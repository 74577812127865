/* global Office */

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return;
};

export const openInNewTab = (url) => {
  Office.context.ui.hasOwnProperty('openBrowserWindow') ? Office.context.ui.openBrowserWindow(url) : window.open(url);
};
