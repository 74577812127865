import * as React from 'react';

import { Input } from '@fluentui/react-components';

import FileNameWithIcon from './FileNameWithIcon';
import SingleLine from './SingleLine';

const EditDocumentTitle = ({ title, defaultTitle, onUpdate, editable }) => {
  const styles = {
    input: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: '8px',
    },
    filename: {
      marginBottom: '10px',
    },
    title: {
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

  const parseFileName = (filename) => {
    const regex = new RegExp(/\.[^/.]+$/);
    const parsedFileName = filename.replace(regex, '');

    return parsedFileName;
  };

  const handleUpdate = (filename) => {
    const parsedFileName = parseFileName(filename);

    onUpdate(parsedFileName);
  };

  // React.useEffect(() => {
  //   handleUpdate(defaultTitle);
  // }, [defaultTitle]);

  return (
    <React.Fragment>
      {editable && (
        <>
          <div>
            <label htmlFor="document-title">Document title</label>
            <Input
              id="document-title"
              defaultValue={parseFileName(title || defaultTitle)}
              onBlur={(e) => handleUpdate(e.target.value)}
              placeholder="New title"
              style={styles.input}
              size="small"
              contentAfter=".docx"
            />
          </div>
          <div style={styles.filename}>
            <FileNameWithIcon filename={defaultTitle + '.docx'}>&nbsp;(this file)</FileNameWithIcon>
          </div>
        </>
      )}
      {!editable && (
        <h3 style={styles.title}>
          <SingleLine content={title || defaultTitle}>{title || defaultTitle}</SingleLine>
        </h3>
      )}
    </React.Fragment>
  );
};

export default EditDocumentTitle;
