import { DEAL_TYPE } from '@getoutlaw/outlaw-core/models/Deal';

export const getFile = (filePath) => {
  const parts = filePath.replace(/\\/g, '/').split('/');
  return parts[parts.length - 1];
};

// This sample shows how to get all the slices of a file.
// The asynchronous operation returns a Promise so it can be awaited.
export const getAllSlices = (file) => {
  let isError = false;

  return new Promise(async (resolve, reject) => {
    let documentFileData = [];
    for (let sliceIndex = 0; sliceIndex < file.sliceCount && !isError; sliceIndex++) {
      const sliceReadPromise = new Promise((sliceResolve, sliceReject) => {
        file.getSliceAsync(sliceIndex, (asyncResult) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            documentFileData = documentFileData.concat(asyncResult.value.data);
            sliceResolve({
              IsSuccess: true,
              Data: documentFileData,
            });
          } else {
            file.closeAsync();
            sliceReject({
              IsSuccess: false,
              ErrorMessage: `Error in reading the slice: ${sliceIndex} of the document`,
            });
          }
        });
      });
      await sliceReadPromise.catch((error) => {
        isError = true;
      });
    }

    if (isError || !documentFileData.length) {
      reject('Error while reading document. Please try it again.');
      return;
    }

    file.closeAsync();

    resolve({
      IsSuccess: true,
      Data: documentFileData,
    });
  });
};

export const addFileExtension = (title, origin) => {
  switch (origin) {
    case DEAL_TYPE.EXTERNAL:
      return title + '.pdf';
    case DEAL_TYPE.NATIVE:
      return title + '.vine';
    case DEAL_TYPE.INGESTED:
    case DEAL_TYPE.EXTERNAL_WORD:
    default:
      return title + '.docx';
  }
};
