/* eslint-disable no-console */
import Packet from '@getoutlaw/outlaw-core/models/Packet';
import { isAdmin } from '@getoutlaw/outlaw-core/models/User';

import GA from 'react-ga';
import GTM from 'react-gtm-module';

// import CONFIG from '@root/Config';
import { config as CONFIG } from './taskpane/index';

const Category = {
  DEAL: 'DEAL',
  USER: 'USER',
  INVITE: 'INVITE',
};

class Dealer {
  gaID = null;

  init(gaID) {
    this.gaID = gaID;

    // INIT Google Analytics
    if (!this.disabled) GA.initialize(this.gaID);

    // INIT Google Tag Manager
    if (CONFIG.GTM) {
      GTM.initialize({
        gtmId: CONFIG.GTM.ID,
        auth: CONFIG.GTM.AUTH,
        preview: CONFIG.GTM.ENV,
      });
    }
  }

  get disabled() {
    return !this.gaID;
  }

  call(packetData) {
    if (this.disabled) return;

    const packet = new Packet(packetData);
    GA.event(packet.ga);
    console.log('[DEALER] EVENT:', packet.ga);
  }

  page() {
    if (this.disabled) return;

    const page = location.pathname;
    GA.set({ page });
    GA.pageview(page);
    console.log('[GA]', page);

    if (typeof window == 'object' && typeof window.Intercom == 'function') {
      window.Intercom('update');
    }
  }

  get mobile() {
    //https://stackoverflow.com/questions/36297612/window-innerwidth-in-chromes-device-mode
    if (typeof window == 'object')
      return (window.visualViewport ? window.visualViewport.width : window.innerWidth) < 768;
    else return false;
  }

  login(account) {
    if (this.disabled) return;

    if (isAdmin(account)) {
      console.log('[GA] Disabling Google Analytics for @outlaw.com users');
      window[`ga-disable-${this.gaID}`] = true;
    }

    // identify user on GA
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
    // https://github.com/react-ga/react-ga
    GA.set({ userId: account.uid });
    // console.log('[DEALER] User login:', account.uid);
  }

  logout() {
    if (this.disabled) return;

    GA.set({ userId: null });
    console.log('[DEALER] User logout.');
  }
}

const DealerInstance = new Dealer();

export default DealerInstance;
export { Category };
