// const config = require('../config/config.${process.env.mode}');

const demo = require('../config/config.demo.js');
const app = require('../config/config.production.js');
const cla = require('../config/config.cla.js');
const imeg = require('../config/config.imeg.js');
const fv = require('../config/config.filevine.js');
const canada = require('../config/config.ca.js');
const vertafore = require('../config/config.vertafore.js');
const invincible = require('../config/config.invincible.js');

let config;

if (process.env.mode === 'development') {
  config = { app: demo, demo }; //Demo is default config ('app')
} else {
  config = {
    app,
    cla,
    imeg,
    fv,
    canada,
    vertafore,
    invincible,
  };
}

export default config;
