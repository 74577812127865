import { createContext } from 'react';

const routeContext = createContext({
  currentView: 'login',
  setCurrentView: () => console.log('nothing provided'),
  addToViewStack: () => console.log('nothing provided'),
  removeFromViewStack: () => console.log('nothing provided'),
  setDialog: () => console.log('nothing provided'),
  dialog: null,
  user: null,
  loggedOut: null,
  setLoggedOut: () => console.log('nothing provided'),
  setDocumentProperty: () => console.log('not implemented'),
  instance: null,
  setInstance: () => console.log('nothing provided'),
});

export default routeContext;
