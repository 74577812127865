import * as React from 'react';

import { Input, Label } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { DatePicker } from '@fluentui/react-datepicker-compat';

import { checkDate, getFormattedTime, getFullDate, getPrettyDate, parseTime } from '../utils/time';

const styles = {
  root: { width: '100%', display: 'flex', flexDirection: 'column', gap: '16px' },
  columns: { display: 'flex', width: '100%', gap: '5px', justifyContent: 'space-between' },
  column: { flex: 1 },
  fluidInput: {
    minWidth: 'auto',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  timeError: { marginTop: '1rem' },
};

const DateTimePicker = ({ currentDateTime, minDateString = null, onChange, onDateValidation }) => {
  const now = !!currentDateTime ? new Date(currentDateTime) : new Date();
  const minDate = !!minDateString ? new Date(minDateString) : null;

  const [date, setDate] = React.useState(now);
  const [time, setTime] = React.useState(getFormattedTime(now));
  const [timeError, setTimeError] = React.useState(false);

  const inputRef = React.useRef();

  const isValidDate = (fullDate) => checkDate(minDate, new Date(fullDate), onDateValidation);

  const handleTimeChange = (value) => {
    const timestring = parseTime(value);

    onDateValidation(false); //Clear any error message
    setTimeError(!timestring);
    const fullDate = getFullDate({ date, time: timestring });

    if (timestring) setTime(timestring);
    if (!timestring || !isValidDate(fullDate)) {
      inputRef.current.focus();
      return;
    }

    onChange(fullDate);
  };

  let timeTimer;
  const onTimeChange = () => {
    clearTimeout(timeTimer);
    setTimeError(false);
    const { current } = inputRef;

    timeTimer = setTimeout(() => {
      current.blur();
    }, 600);
  };

  const onFormatDate = (date) => getPrettyDate(date);

  return (
    <React.Fragment>
      <section style={styles.root}>
        <section>
          <div>
            <Label>Date</Label>
          </div>
          <DatePicker
            placeholder="Select a date..."
            maxDate={now}
            minDate={minDate}
            // initialPickerDate={now}
            value={now}
            formatDate={onFormatDate}
            onSelectDate={(d) => {
              const fullDate = getFullDate({ date: d, time });
              setDate(d);

              if (!isValidDate(fullDate)) return;

              onChange(fullDate);
            }}
            className="dateTimePicker"
            size="small"
            style={styles.fluidInput}
          />
        </section>
        <section>
          <div>
            <Label>Time</Label>
          </div>
          <Input
            placeholder={getFormattedTime(now)}
            onBlur={(e) => {
              const value = e.target.value;
              handleTimeChange(value);
            }}
            onChange={onTimeChange}
            defaultValue={time}
            style={styles.fluidInput}
            size="small"
            spellCheck={false}
            ref={inputRef}
          />
          {timeError && (
            <Alert intent="info" style={styles.timeError}>
              Please use this format&nbsp;<code>12:34 pm</code>
            </Alert>
          )}
        </section>
      </section>
    </React.Fragment>
  );
};

export default DateTimePicker;
