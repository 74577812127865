import * as React from 'react';

import { Button } from '@fluentui/react-components';
import { ChevronLeft12Regular as ChevronLeft, ChevronRight12Regular as ChevronRight } from '@fluentui/react-icons';

const Pagination = ({ results, loadingMsg, onChange, style }) => {
  let { currentPage = 0, totalPages = 1 } = results;
  if (totalPages < 1) totalPages = 1;

  const BUTTON_COLOR = '#f3f3f3';

  const styles = {
    columns: {
      width: '100%',
      display: 'flex',
    },
    column: { flex: '0 0 50%', width: 0, overflow: 'hidden', textOverflow: 'ellipsis' },
    wrapper: { justifyContent: 'space-between' },
    dropdown: {
      width: '100%',
      display: 'inline',
    },
    pagination: {
      marginBottom: '1rem',
      background: BUTTON_COLOR,
      whiteSpace: 'nowrap',
      fontSize: '12px',
      ...style?.pagination,
    },
    backBtn: { width: 0, minWidth: 'initial' },
    pageForward: { paddingRight: '0', minWidth: '1em' },
    disabled: {
      color: !results.deals ? '#ccc' : 'initial',
    },
  };

  return (
    <section style={styles.pagination}>
      <div style={{ ...styles.columns, ...styles.wrapper }}>
        <div>
          {results.deals && loadingMsg}
          {!results.deals && 'Showing 0'}
          <Button
            appearance="transparent"
            size="small"
            icon={<ChevronRight color={BUTTON_COLOR} visibility="hidden" />}
            iconPosition="after"
            style={styles.backBtn}
          />
        </div>
        {!results.loading && (
          <div>
            <Button
              onClick={() => onChange({ searchParams: { page: currentPage - 1 } })}
              disabled={currentPage === 0 || !results.deals}
              appearance="transparent"
              icon={<ChevronLeft />}
              size="small"
            />
            <span style={styles.disabled}>
              {currentPage + 1} / {totalPages}
            </span>
            <Button
              onClick={() => onChange({ searchParams: { page: currentPage + 1 } })}
              disabled={currentPage + 1 === totalPages || !results.deals}
              appearance="transparent"
              icon={<ChevronRight />}
              size="small"
              style={styles.pageForward}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Pagination;
