import React from 'react';

import { Button, makeStyles } from '@fluentui/react-components';

import viewContext from '../contexts/viewContext';
// import Auth from '@root/Auth';
import { fireAuth as Auth } from '../index';

const useStyles = makeStyles({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
});

const styles = {
  text: {
    flex: 1,
  },
};

const SingleSignOnButton = ({
  _block,
  _dealerLabel,
  domain,
  providerName,
  _textTemplate,
  _usePopup = false,
  onClick,
  onError,
}) => {
  const classes = useStyles();
  const view = React.useContext(viewContext);

  const handleClick = () => {
    console.log('handleClick()', domain, Auth);
    //Dealer stuff here?

    onClick();

    try {
      Auth.signInWithPopup(domain);
    } catch (e) {
      console.log('Auth error', e);
      onError('There was an error.  Please try again.');
    }

    return;

    Office.context.ui.displayDialogAsync(
      window.origin + `/taskpane.html?view=load-auth&domain=${domain}`,
      { width: 50, height: 75 },
      (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const dialog = result.value;

          view.setDialog(dialog);
        } else {
          console.log('oops, error');
        }
      }
    );

    onClick();

    // await Auth.login(domain);
  };

  return (
    <Button onClick={() => handleClick(providerName)} className={classes.button}>
      <img src={require(`/assets/svg/login-${providerName.toLowerCase()}.svg`)} alt={providerName} height="20px" />
      <span style={styles.text}>Continue with {providerName}</span>
    </Button>
  );
};

export default SingleSignOnButton;
