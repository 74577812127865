import * as React from 'react';

import { Combobox, Option } from '@fluentui/react-components/unstable';

import API from '@root/ApiClient';

const FacetSearch = ({ facet, selected, toggleSelection, style }) => {
  const [value, setValue] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [focused, setFocused] = React.useState(focused);

  React.useEffect(() => {
    // If searching for an empty string, clear results
    if (!value.length) {
      setSuggestions([]);
      setLoading(false);
      return;
    }

    const getSuggestions = setTimeout(() => {
      setLoading(true);

      API.call('getFacetSuggestions', { facet, query: value }, (suggestions) => {
        console.log('facetsearch suggestions', suggestions);
        setSuggestions(suggestions);
        setLoading(false);
      });
    }, 300);

    return () => clearTimeout(getSuggestions);
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSelect = (_e, data) => {
    toggleSelection(data.optionValue);
  };

  const getPlaceholder = () => {
    if (!!selected.length) return selected.join(', ');
    if (!suggestions.length && !value) return `Begin searching ${facet}`;
    if (!suggestions.length && !loading && value) return 'No results';
  };

  return (
    <Combobox
      freeform={true}
      value={value}
      onChange={handleChange}
      onOptionSelect={handleSelect}
      size="small"
      style={{ ...style, ...{ paddingBottom: '0px', paddingTop: '0px' } }}
      placeholder={getPlaceholder()}
      multiselect={true}
      selectedOptions={selected}
      spellCheck={false}
      autoFocus={true}
    >
      {loading && (
        <Option disabled={true} checkIcon={<></>}>
          Loading ...
        </Option>
      )}
      {suggestions &&
        suggestions.map((suggestion, i) => (
          <Option key={`${suggestion.email}-${i}`} value={suggestion.email || suggestion.key}>
            {suggestion.key} {suggestion.email ? `(${suggestion.email})` : ''}
          </Option>
        ))}
    </Combobox>
  );
};

export default FacetSearch;
