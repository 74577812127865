import DealRecord from '@getoutlaw/outlaw-core/models/DealRecord';

import API from '@root/ApiClient';

// import CONFIG from '@root/Config';
import { config as CONFIG } from '../index';

export const getBaseUrl = () =>
  process.env.mode === 'production' ? `https://${CONFIG.DOMAIN}` : 'https://localhost:8443'; //Run Outlaw using SSL on this port

export const getDealUrl = (dealID) => `${getBaseUrl()}/deals/${dealID}`;

export const getDeals = async ({
  team,
  user,
  template,
  filterValue,
  filterType,
  searchParams,
  onDocumentStep,
  onDealResults,
}) => {
  if (!team) {
    onDocumentStep('Please select a team');
    onDealResults({ deals: null, loading: false });
    return;
  }

  onDealResults({ deals: [], loading: true });
  onDocumentStep(`Fetching ...`);

  const facets = [];
  if (template) facets.push({ facet: 'sourceTemplate', values: [template.key] });
  if (team) facets.push({ facet: 'teamID', values: [team.teamID] });

  const apiArgs = { facets, ...searchParams };

  console.log('incoming', apiArgs, { filterType, filterValue });

  const hasValue = Array.isArray(filterValue) ? filterValue.length > 0 : !!filterValue;

  if (hasValue) {
    switch (filterType) {
      case 'title':
        apiArgs.name = filterValue;
        break;
      case 'parties':
        apiArgs.facets.push({ facet: 'parties', values: Array.isArray(filterValue) ? filterValue : [filterValue] });
        break;
      case 'users':
        apiArgs.facets.push({ facet: 'users', values: Array.isArray(filterValue) ? filterValue : [filterValue] });
        break;
    }
  }

  console.log('ApiArgs', apiArgs);

  API.call(
    'getDeals',
    apiArgs,
    (results) => {
      onDocumentStep('Fetching deals');

      // Preview deals should never be indexed, so this should essentially never do anything visible
      // But there may be some cases with old/legacy data where it still made its way in
      // So this is just to ensure that we NEVER show preview deals in the deals list
      results.hits = results.hits.filter((dealRecord) => !dealRecord.preview);

      // results is the json obj from Aloglia. results.hits are an untyped array of DealRecord objects */
      const deals = results.hits.map((record) => new DealRecord(record, user.id));

      const from = Number(results.page * results.hitsPerPage + 1).toLocaleString();
      const to = Math.min(
        results.nbHits,
        Number(results.page * results.hitsPerPage + results.hitsPerPage)
      ).toLocaleString();
      const total = Number(results.nbHits).toLocaleString();

      if (deals.length > 0) {
        onDocumentStep(`Showing  ${from} - ${to} of ${total} `);
      } else {
        onDocumentStep(`No documents found`);
      }

      // Set deal tags here to avoid doing it in the table rendering (this.columns)
      deals.forEach((deal) => {
        deal.userTags = user.tags.get(deal.tags);
      });

      onDealResults({
        deals,
        totalHits: results.nbHits,
        currentPage: results.page,
        totalPages: results.nbPages,
        hasArchived: results.hasArchived,
        loading: false,
      });
    },
    (e) => {
      console.error(e);
      onDocumentStep(e.error);
      onDealResults({ deals: null, loading: false });
    }
  );
};

export const getLatestVersion = (versions) => {
  if (!versions) return null;

  return Object.values(versions)
    .sort((a, b) => parseInt(a.dateCreated) - parseInt(b.dateCreated))
    .pop();
};
