import * as React from 'react';

import { default as DealVersionModel } from '@getoutlaw/outlaw-core/models/DealVersion';

import API from '@root/ApiClient';

import documentContext from '../contexts/documentContext';
import viewContext from '../contexts/viewContext';
// import Fire from '@root/Fire';
import { fireApp as Fire } from '../index';
import { getDeals } from '../utils/deals';
import { STEPS, createOrUpdateDocument } from '../utils/document';
import { getFile } from '../utils/file';
import { fetchTemplateData } from '../utils/template';
import { FirstStep, SaveUpdateStep, SavingStep, TemplateStep, UpdateStep } from './SyncDocument/index';

const SyncDocument = () => {
  const view = React.useContext(viewContext);
  const { user, existingDealID } = view;
  const [filePath, setFilePath] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [newFileName, setNewFileName] = React.useState('');
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState('');
  const [documentStep, setDocumentStep] = React.useState('');
  const [templates, setTemplates] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [selectedTemplateData, setSelectedTemplateData] = React.useState(null);
  const [uploadedDocument, setUploadedDocument] = React.useState(null);
  const [newDocument, setNewDocument] = React.useState({
    status: null,
    attribution: null,
    description: null,
    date: 'now',
    dateValue: null,
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [dealResults, setDealResults] = React.useState({
    deals: null,
    totalHits: 0,
    currentPage: 0,
    totalPages: 0,
    hasArchived: false,
    loading: false,
  });
  const [currentFilter, setCurrentFilter] = React.useState('');
  const [currentFilterType, setCurrentFilterType] = React.useState('title');
  const [alertMsg, setAlertMsg] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [savedVariables, setSavedVariables] = React.useState(null);
  const [showAdvancedSearch, setShowAdvancedSearch] = React.useState(false);
  const [selectedSuggestions, setSelectedSuggestions] = React.useState([]);
  const [teams, setTeams] = React.useState([]);

  const updateFilePathProperties = (fileUrl) => {
    setFilePath(fileUrl);
    setFileName(getFile(fileUrl));
  };

  React.useEffect(async () => {
    Office.context.document.getFilePropertiesAsync((result) => {
      const fileUrl = result.value.url;

      if (fileUrl == '') {
        setFilePath('Save the document first');
      } else {
        updateFilePathProperties(fileUrl);
      }
    });

    // Office.context.document.addHandlerAsync('documentSelectionChanged', ({ document }) => {
    //   console.log('has changes', document);
    //   setHasChanges(true);

    //   if (filePath !== document.url) updateFilePathProperties(document.url);
    // });
  }, [Office.context.document]);

  React.useEffect(async () => {
    if (!user) return;

    setNewDocument({ ...newDocument, attribution: user.id });

    setTeams(await Fire.getUserTeams(user.id));
  }, [user]);

  React.useEffect(async () => {
    if (!existingDealID) return;

    console.log('effect!  bc we do have a deal id', existingDealID);

    return;

    const deal = await Fire.getDeal(existingDealID);
    const team = await Fire.getTeam(deal.team);

    console.log('deal', deal);
    console.log(deal.team);
    console.log('team', team);

    setUploadedDocument(deal);
    await teamUpdated(team);
    setCurrentStep(STEPS.uploaded);
  }, [existingDealID]);

  const selectTemplateStep = async () => {
    await setUploadedDocument(null);
    getTemplates(selectedTeam.teamID);
    setCurrentStep(STEPS.selectTemplate);
  };

  const getTemplates = async (teamID) => {
    setErrorMsg(null);
    let templates;

    try {
      templates = await API.call('getTeamTemplates', { teamID, activeOnly: true });
      setTemplates(templates);
    } catch (e) {
      console.log(e, typeof e, Object.keys(e));
      setErrorMsg(e.error);
    }
  };

  const teamUpdated = async (team) => {
    setIsSaving(true);
    setErrorMsg(null);

    console.log('teamUpdated', team);
    console.log(user);

    return API.call('getTeamMembers', { uid: user.id, teamID: team.teamID })
      .then((members) => {
        console.log('getTeamMembers', members);
        team.members = members;
        team.userCanCreateDoc = user.canCreateNewDoc(team);
        team.userCanEdit = user.canEditTeam(team.teamID);

        setSelectedTeam(team);
        getTemplates(team.teamID);
      })
      .catch((e) => {
        console.log('getTeamMembers error', e);
        let { message } = e;
        if (typeof message === 'object' && !!message.message) message = message?.message;
        setErrorMsg(`Oops, something went wrong: ${message}`);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getDealsFromState = ({
    team = selectedTeam,
    template = selectedTemplate,
    filterValue = currentFilter,
    filterType = currentFilterType,
    searchParams = {},
  }) => {
    return getDeals({
      team,
      user,
      template,
      filterValue,
      filterType,
      searchParams,
      onDocumentStep: setDocumentStep,
      onDealResults: setDealResults,
    });
  };

  const logAndSetDocStep = (step) => {
    if (step) {
      console.log(`[Doc Create] ${step}`);
      setDocumentStep(step);
    } else {
      setDocumentStep(null);
    }
  };

  const createOrUpdateDocumentFromState = async ({ dealID = null, incomingDocument }) => {
    console.log('createOrUpdateDocumentFromState', newDocument, incomingDocument);

    setErrorMsg(null);

    try {
      await createOrUpdateDocument({
        dealID,
        user,
        selectedTeam,
        newDocument: { ...newDocument, ...incomingDocument },
        selectedTemplate,
        title: newFileName || fileName,
        onStepChange: setCurrentStep,
        onDocStepChange: logAndSetDocStep,
        onUploadedDocument: (newDeal) => {
          console.log({ newDeal });
          if (dealID) {
            //Existing deal
            console.log('onUploadedDocument should append', newDeal);

            const dr = { ...dealResults };
            console.log({ dealResults });
            // dr.deals ? dr.deals.push(newDeal) : (dr.deals = [newDeal]);
            dr.deals = dr.deals
              ? dr.deals.map((deal) =>
                  deal.dealID === newDeal.dealID && newDeal?.versions
                    ? { ...newDeal.deal, versions: Object.values(newDeal.deal.versions) }
                    : deal
                )
              : [newDeal];

            console.log({ dr });

            setDealResults(dr);
            setUploadedDocument(newDeal);
          } else {
            //New deal
            console.log('new file, wont append');
            view.setDocumentProperty('dealID', newDeal.dealID, () => console.log('Added dealID to doc'));
            // setNewFile(newDeal);
            setUploadedDocument(newDeal);
          }

          // setNewDocument({ ...newDocument, description: '', dateValue: newDocument.dateValue + 60 * 1000 });
          setNewDocument({ ...newDocument, description: '', date: 'now', dateValue: null, pendingChanges: false });
        },
        onError: (e) => {
          console.log('SyncDoc upload error', e);
          setErrorMsg(e?.message || e);
          setTimeout(() => setCurrentStep(STEPS.save), 0);
        },
      });
    } catch (e) {
      console.log('----?', e);
    }
  };

  const saveWorkflow = ({ workflow }) => {
    setSelectedTemplateData({ ...workflow });
    setNewDocument({ ...newDocument, status: workflow.workflow.steps[0].key });
  };

  const saveVariables = (dealID, variables, success, failure) => {
    Fire.saveVariables(
      dealID,
      variables,
      (e) => success(e),
      (e) => failure(e)
    );
  };

  const handleSaveVariables = (variables, success, failure) => {
    setSavedVariables(variables);

    //Do we need to convert to Variable?

    if (uploadedDocument) {
      setAlertMsg(null);
      saveVariables(uploadedDocument.dealID, variables, success, failure);
    } else {
      setNewDocument({ ...newDocument, variables });
      setAlertMsg('Variables set.  Now, save the file to Outlaw');
    }
  };

  const refreshUploadedDocument = async () => {
    const deal = await Fire.getDeal(uploadedDocument.dealID);

    setUploadedDocument(deal);
  };

  /*
  const teamMenu = () => (
    <TeamMenu
      onOptionSelect={async (team) => {
        console.log('teammenu on option select');
        setIsSaving(true);
        setSelectedTemplate(null);
        await teamUpdated(team);
        getDealsFromState({ team });
        setIsSaving(false);
      }}
      selectedTeam={selectedTeam}
      size="small"
      appearance="underline"
      disabled={isSaving}
    />
  );
  */

  const CurrentView = () => {
    switch (currentStep) {
      case STEPS.saving:
        return <SavingStep />;
      case STEPS.save:
      case STEPS.uploaded:
        return <SaveUpdateStep />;
      case STEPS.update:
        return <UpdateStep />;
      case STEPS.selectTemplate:
        return <TemplateStep />;
      case '':
      default:
        return <FirstStep />;
    }
  };

  //Split into multiple contexts?
  return (
    <documentContext.Provider
      value={{
        uploadedDocument,
        setUploadedDocument,
        fileName,
        selectedTeam,
        teamUpdated,
        isSaving,
        errorMsg,
        setErrorMsg,
        selectTemplateStep,
        user,
        setSelectedTemplate,
        templates,
        setCurrentStep,
        fetchTemplateData,
        selectedTemplate,
        saveWorkflow,
        currentFilterType,
        setCurrentFilterType,
        getDealsFromState,
        dealResults,
        documentStep,
        newFileName,
        setNewFileName,
        createOrUpdateDocumentFromState,
        selectedTemplateData,
        savedVariables,
        handleSaveVariables,
        newDocument,
        setNewDocument,
        currentFilter,
        setCurrentFilter,
        setSelectedTemplateData,
        showAdvancedSearch,
        setShowAdvancedSearch,
        setDocumentStep,
        refreshUploadedDocument,
        selectedSuggestions,
        setSelectedSuggestions,
        teams,
      }}
    >
      <CurrentView />
    </documentContext.Provider>
  );
};

export default SyncDocument;
