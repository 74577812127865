import * as React from 'react';

import { Document16Filled as DocumentIcon } from '@fluentui/react-icons';
import { tokens } from '@fluentui/react-theme';

import SingleLine from './SingleLine';

const FileNameWithIcon = ({ filename, children }) => {
  const styles = {
    root: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'flex',
      lineHeight: 1,
    },
    documentIcon: {
      marginRight: '4px',
    },
    filename: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      fontStyle: filename ? 'normal' : 'italic',
      color: filename ? 'initial' : '#777',
    },
  };

  return (
    <div style={styles.root}>
      <img src={require('/assets/20x20-file-docx.svg')} height={14} style={styles.documentIcon} />
      <SingleLine content={filename || 'Untitled ...'}>
        <span style={styles.filename}>{filename || 'Untitled ...'}</span>
      </SingleLine>
      {children}
    </div>
  );
};
export default FileNameWithIcon;
