import * as React from 'react';

import { Button, Spinner } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { ChevronLeft12Regular as ChevronLeft, Search20Regular as SearchIcon } from '@fluentui/react-icons';
import DealRole from '@getoutlaw/outlaw-core/enums/DealRole';
import { default as DealVersionModel } from '@getoutlaw/outlaw-core/models/DealVersion';

import documentContext from '../../contexts/documentContext';
// import Fire from '../../../Fire';
import { fireApp as Fire } from '../../index';
import { STEPS } from '../../utils/document';
import { getLastSaveTime } from '../../utils/document';
import { addFileExtension } from '../../utils/file';
import AdvancedSearch from '../AdvancedSearch';
import DealVersionComponent from '../DealVersion';
import DialogAlert from '../DialogAlert';
import PaginationComponent from '../Pagination';
import TemplateMenu from '../TemplateMenu';

/* global Word */

const renderUpdateStep = () => {
  const {
    selectedTeam,
    user,
    setCurrentStep,
    currentFilterType,
    setCurrentFilterType,
    currentFilter,
    setCurrentFilter,
    selectedTemplate,
    getDealsFromState,
    templates,
    dealResults,
    documentStep,
    setUploadedDocument,
    setNewDocument,
    setSelectedTemplate,
    setSelectedTemplateData,
    showAdvancedSearch,
    setShowAdvancedSearch,
    setDocumentStep,
    fetchTemplateData,
    saveWorkflow,
  } = React.useContext(documentContext);

  const [writeError, setWriteError] = React.useState(false);
  const [lastSaveTime, setLastSaveTime] = React.useState(new Date());

  React.useEffect(() => {
    getLastSaveTime().then((lastSaveTime) => setLastSaveTime(lastSaveTime));
  }, []);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      padding: '0 16px',
    },
    columns: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
    },
    hColumn: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'middle',
    },
    backBtn: {
      paddingLeft: 0,
      minWidth: 'unset',
      width: '1em',
      padding: 0,
    },
    searchIcon: { paddingRight: 0, minWidth: 'unset', width: 'auto' },
    pagination: { pagination: { padding: '0px 16px', marginBottom: '3.5rem' } },
  };

  const Pagination = ({ style }) => (
    <PaginationComponent
      results={dealResults}
      loadingMsg={documentStep}
      onChange={(params) => getDealsFromState(params)}
      style={style}
    />
  );

  const setupFromExistingDeal = async (dealID, deal = null, onSuccess, onError) => {
    console.log('setupFromExistingDeal', { dealID, deal, onSuccess, onError });
    let existingDeal;
    try {
      console.log('setupFromExistingDeal a', dealID);
      existingDeal = await Fire.getDeal(dealID);
      console.log('setupFromExistingDeal b');
    } catch (e) {
      console.log('setupFromExistingDeal c');
      onError('We could not fetch this deal');
      return;
    }

    console.log('setupFromExistingDeal d');

    //Somehow the existingDeal doesn't have updated parties.  Does Fire.getDeal() have caching?
    if (deal) existingDeal.parties = deal.parties;

    const dealUser = existingDeal.users.find((du) => du.key === user.id);

    console.log({ dealUser, existingDeal });

    const canEdit = [DealRole.OWNER, DealRole.EDITOR, DealRole.PROPOSER].includes(dealUser.role);
    console.log({ canEdit });
    if (!canEdit) {
      //!dealUser?.canEdit
      onError(`User role cannot edit this deal: ${dealUser.role}`);
      return;
    }

    const workflowData = await Fire.getWorkflow(existingDeal);
    const sortedVersions = Object.keys(existingDeal.versions).sort(
      (a, b) => existingDeal.versions[a].dateCreated > existingDeal.versions[b].dateCreated
    );

    console.log('updateStep debug 1');

    const latestVersion = existingDeal.versions[sortedVersions[sortedVersions.length - 1]];

    console.log('updateStep debug 2', latestVersion);

    console.log('updateStep debug 3', lastSaveTime);

    await setUploadedDocument(existingDeal);
    await setNewDocument({
      ...setNewDocument,
      ...{
        status: latestVersion.dealStatus,
        attribution: user.id,
        date: 'now',
        dateValue: null, //lastSaveTime,
        pendingChanges: false,
      },
    });
    await setSelectedTemplateData({ workflow: workflowData.workflow });
    // await setSelectedTemplate({ ...existingDeal, variables: existingDeal.variables });

    onSuccess();
  };

  return (
    <>
      <DialogAlert
        title="Can not update document"
        buttonText="Select another document"
        // onChange={setWriteError}
        onClick={() => setWriteError(false)}
        isVisible={!!writeError}
      >
        {writeError}
      </DialogAlert>

      <section style={{ background: '#f3f3f3', marginBottom: '16px', padding: '8px 0' }}>
        <div style={styles.container}>
          {!showAdvancedSearch && (
            <div style={styles.columns}>
              <div style={styles.hColumn}>
                <Button
                  appearance="transparent"
                  size="small"
                  icon={<ChevronLeft />}
                  onClick={() => {
                    setCurrentStep('');
                    setSelectedTemplate(null);
                  }}
                  style={styles.backBtn}
                />
                <h3 style={{ margin: 0 }}>Select existing document</h3>
              </div>

              <div>
                <Button
                  appearance="transparent"
                  icon={<SearchIcon />}
                  onClick={() => setShowAdvancedSearch(true)}
                  style={styles.searchIcon}
                />
              </div>
            </div>
          )}

          {showAdvancedSearch && (
            <AdvancedSearch
              onBack={() => {
                setShowAdvancedSearch(false);
                setCurrentFilter(null);
                getDealsFromState({ filterValue: '' });
              }}
              onSearch={({ filterValue, filterType }) => {
                console.log('update step currentfilter', filterValue);
                setCurrentFilter(filterValue);
                setCurrentFilterType(filterType);
                getDealsFromState({ filterValue, filterType });
              }}
              user={user}
              filterValue={currentFilter}
              filterType={currentFilterType}
              menus={
                [
                  // templateMenu,
                  //teamMenu,
                ]
              }
            />
          )}

          <TemplateMenu
            // placeholder={`All templates (${templates.length})`}
            disabled={!selectedTeam || !templates}
            templates={templates}
            selectedTemplate={selectedTemplate}
            onOptionSelect={async (template) => {
              setSelectedTemplate(template);
              setDocumentStep('Fetching existing documents for team');

              await fetchTemplateData({
                team: selectedTeam,
                template,
                onFetch: saveWorkflow,
              });

              setDocumentStep('Getting deals');
              getDealsFromState({ template });
            }}
            onReset={() => {
              setSelectedTemplate(null);
              getDealsFromState({ template: null });
            }}
          />

          {/* {!dealResults.loading && !dealResults.deals?.length && <h2>No documents found</h2>} */}

          {!dealResults.loading && !dealResults.deals?.length && currentFilter && (
            <Alert intent="info">
              No results for {currentFilterType}:{' '}
              {Array.isArray(currentFilter) ? currentFilter.join(', ') : currentFilter}
            </Alert>
          )}

          <Pagination style={{ pagination: { margin: '0' } }} />
        </div>
      </section>
      {dealResults.loading && <Spinner style={{ marginBottom: '1rem' }} />}
      {!!dealResults.deals?.length && (
        <section>
          {dealResults.deals.map((deal) => {
            // const currentVersion = !!deal.versions.length ? deal.currentVersion : deal.originalVersion;
            const currentVersion =
              deal?.currentVersion ||
              (deal?.versions.length > 0 &&
                Array.isArray(deal.versions) &&
                deal.versions.sort((a, b) => parseInt(a.dateCreated) < parseInt(b.dateCreated))[0].id);

            const version = (Array.isArray(deal.versions) ? deal.versions : Object.values(deal.versions)).find(
              (v) => v.id === currentVersion
            );

            const attachments = Array.isArray(deal.attachments)
              ? deal.attachments.reduce((acc, attachment) => {
                  if (!attachment) return acc;
                  acc[attachment.key] = attachment;

                  return acc;
                }, {})
              : deal.attachments;

            const dealVersion = new DealVersionModel(
              version,
              { ...deal, attachments },
              version?.docxKey || version?.pdfKey || null
            );

            return (
              <DealVersionComponent
                key={deal.dealID}
                index={!!deal.versions.length ? deal.versions.length : 1}
                isLatest={true}
                deal={deal}
                title={deal.name || deal.info.name}
                filename={
                  dealVersion.deal?.attachments[dealVersion?.docxKey || dealVersion?.pdfKey]?.filename ||
                  addFileExtension(deal.name, version?.origin)
                }
                version={version}
                workflowColor={deal.statusColor}
                user={user}
                onClick={async () => {
                  console.log('UpdateStep onClick');
                  await setupFromExistingDeal(
                    deal.dealID,
                    deal,
                    () => {
                      setWriteError(false);
                      setCurrentStep(STEPS.save);
                    },
                    (err) => {
                      setWriteError(err);
                    }
                  );
                }}
                onError={setWriteError}
              />
            );
          })}
        </section>
      )}
      {dealResults.deals?.length > 0 && <Pagination style={styles.pagination} />}
    </>
  );
};

export default renderUpdateStep;
