const config = {
  INTERCOM: 'fioelmm8',
  FIREBASE: {
    apiKey: 'AIzaSyDmqVqkTidZ5YRf62XOkRL-in5OTPWt4is',
    //authDomain: "outlaw-canada-1.firebaseapp.com",
    authDomain: 'app.getoutlaw.ca',
    projectId: 'outlaw-canada-1',
    storageBucket: 'outlaw-canada-1.appspot.com',
    messagingSenderId: '972202545478',
    appId: '1:972202545478:web:0289e45f791498ca525547',
    measurementId: 'G-R1VCCSZ2RC',
  },
  // Set instance name e.g "coffee"
  INSTANCE: 'canada',
  DOMAIN: 'app.getoutlaw.ca',
};

module.exports = config;
