import * as React from 'react';

import { DEFAULT_COLOR } from '@getoutlaw/outlaw-core/models/Branding';

const StatusLabel = ({ status, color = DEFAULT_COLOR }) => {
  const styles = {
    root: {
      textTransform: 'uppercase',
      backgroundColor: color,
      color: '#ffffff',
      fontSize: '8px',
      fontWeight: 700,
      padding: '3px 4px',
      width: '80px',
      maxWidth: '80px',
      minWidth: '80px',
      lineHeight: 'initial',
      letterSpacing: '0.3px',
      borderRadius: '2px',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textTransform: 'uppercase',
      textShadow: '0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.3)',
      whiteSpace: 'nowrap',
    },
  };

  return <div style={styles.root}>{status}</div>;
};

export default StatusLabel;
