import * as React from 'react';

const styles = {
  root: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  userName: {},
  partyName: {
    color: '#8c98a5',
  },
};
const Party = ({ party }) => {
  if (!party?.legalName) return '';

  const legalName = party.legalName ?? '';

  const usernames = (party?.userNames || []).join(', ');

  return (
    <div style={styles.root}>
      {usernames ||
        (legalName && (
          <span style={styles.userName}>
            {usernames}
            {usernames && party?.legalName && usernames != party.legalName && ', '}
            {usernames !== party.legalName && legalName}
          </span>
        ))}

      <span style={styles.partyName}>{party?.partyName && party.partyName}</span>
    </div>
  );
};

export default Party;
