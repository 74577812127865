const config = {
  FIREBASE: {
    apiKey: 'AIzaSyBZivNKwEFUj0azkFG4bGFbwEZmVlgl_f0',
    //authDomain: "outlaw-imeg.firebaseapp.com",
    authDomain: 'imeg.getoutlaw.com',
    projectId: 'outlaw-imeg',
    storageBucket: 'outlaw-imeg.appspot.com',
    messagingSenderId: '238617657620',
    appId: '1:238617657620:web:f896b8a099933bb7fd0201',
    measurementId: 'G-KGEEYKGL3D',
  },
  INSTANCE: 'imeg',
  DOMAIN: 'imeg.getoutlaw.com',
};

module.exports = config;
