import * as React from 'react';

import { Spinner } from '@fluentui/react-components';

import documentContext from '../../contexts/documentContext';

const SavingStep = () => {
  const { uploadedDocument, documentStep } = React.useContext(documentContext);

  return (
    <div className="container">
      <Spinner
        labelPosition="below"
        label={`Saving ${uploadedDocument?.versions ? 'version' : 'file'}`}
        style={{ paddingTop: '16px' }}
      />
      {/* {documentStep} */}
    </div>
  );
};

export default SavingStep;
