import * as React from 'react';

import { Link } from '@fluentui/react-components';

const styles = {
  root: {
    display: 'inline-flex',
    padding: 0,
    margin: 0,
    fontWeight: 500,
  },
  icon: { margin: '3px 4px 0' },
};

const InlineLink = ({ href, icon, iconPosition = 'after', style, children, ...props }) => {
  const Icon = () => {
    return { ...icon, props: { ...icon.props, style: styles.icon } };
  };

  return (
    <Link appearance="subtle" inline={true} href={href} style={{ ...styles.root, ...style }} {...props}>
      {iconPosition !== 'after' && <Icon />}
      {children}
      {iconPosition === 'after' && <Icon />}
    </Link>
  );
};

export default InlineLink;
