// import Fire from '@root/Fire';
import { fireApp as Fire } from '../index';

export const fetchTemplateData = async ({ team, template, onFetch }) => {
  const deal = {
    team: team.teamID,
    isTemplate: true,
    template,
  };
  const workflow = await Fire.getWorkflow(deal);

  onFetch({ workflow });
};
