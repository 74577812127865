import * as React from 'react';

import { Button } from '@fluentui/react-components';
import { ChevronLeftFilled as BackIcon, DismissFilled as DismissIcon } from '@fluentui/react-icons';
import PropTypes from 'prop-types';

const dismiss = () => console.log('What should this do?');

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifycontent: 'space-between',
    paddingTop: '6px',
    paddingBottom: '16px',
    marginBottom: '16px',
    borderBottom: '1px solid #ccc',
  },
  leftCol: {
    flexGrow: 2,
  },
  button: {
    padding: 0,
    margin: 0,
  },
  name: {
    padding: 0,
    margin: 0,
  },
};

const Header = ({ onBack, showBack }) => {
  return (
    <div className="ms-u-fadeIn500" style={styles.root}>
      <div style={styles.leftCol}>
        {showBack && (
          <Button
            onClick={onBack}
            aria-label="Back"
            appearance="transparent"
            size="small"
            icon={<BackIcon />}
            style={styles.button}
          />
        )}
        <Button appearance="transparent" size="small" style={styles.button}>
          <h3 style={styles.name}>Outlaw</h3>
        </Button>
      </div>
      <Button
        onClick={dismiss}
        aria-label="Close"
        appearance="transparent"
        icon={<DismissIcon />}
        style={styles.button}
      />
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  message: PropTypes.string,
};

export default Header;
