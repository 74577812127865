const config = {
  FIREBASE: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: 'demo.getoutlaw.com',
    databaseURL: 'https://outlaw-staging.firebaseio.com',
    storageBucket: 'gs://outlaw-staging.appspot.com/',
    projectId: 'outlaw-staging',
    messagingSenderId: '126911116867',
    appId: '1:126911116867:web:551b3911ea09fa44a606f6',
  },
  INSTANCE: 'demo',
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  DOMAIN: 'demo.getoutlaw.com',
};

module.exports = config;
