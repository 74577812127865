import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { FluentProvider, Spinner, createDarkTheme, createLightTheme } from '@fluentui/react-components';
import Core from '@getoutlaw/outlaw-core/Core.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import { AppContainer } from 'react-hot-loader';

import { OutlawAuth } from '@root/Auth';
import { OutlawFirebase } from '@root/Fire';
import CONFIG from '@root/plugin-config';

import App from './components/App';
import stateFromHTML from './utils/StateFromHTML';

/* global Office, Word */

let isOfficeInitialized = false;
let documentProperties = {};
let platform;
let isSupported = false;
let fireApp;
let fireAuth;
let config = CONFIG['app'];

const title = 'Outlaw Add-in for Word';

let firebaseApp, firebaseAuth, firebaseDB, firebaseStorage;
const initFirebase = (instance) => {
  console.log('initFirebase!', instance);

  config = CONFIG[instance] ?? CONFIG['app'];

  console.log('Instance:', config.INSTANCE);
  console.log('Domain:', config.DOMAIN);

  const { FIREBASE } = config;

  if (!!firebaseApp) {
    firebaseApp = firebase.initializeApp(FIREBASE, instance);
  } else {
    firebaseApp = firebase.initializeApp(FIREBASE); //First, create the default app
  }

  firebaseAuth = firebase.auth(firebaseApp);
  firebaseDB = firebase.database(firebaseApp);
  firebaseStorage = firebase.storage(firebaseApp);

  const Fire = new OutlawFirebase();

  Fire.init(firebaseDB, firebaseAuth, firebaseStorage);

  fireApp = Fire;

  Core.init({ Fire, Config: config, functions: { stateFromHTML } });
};

const initAuth = (authChange, authChangeInProgress) => {
  const Auth = new OutlawAuth();

  Auth.init(firebaseAuth, authChange, authChangeInProgress);

  fireAuth = Auth;
};

const outlawTheme = {
  10: '#040205',
  20: '#1B1325',
  30: '#2D1D41',
  40: '#3C2559',
  50: '#4C2D73',
  60: '#5C358E',
  70: '#6C3DA9',
  80: '#7D46C5',
  90: '#8C53D6',
  100: '#9A65DB',
  110: '#A776DF',
  120: '#B388E4',
  130: '#BF99E8',
  140: '#CBABED',
  150: '#D7BDF1',
  160: '#E2CFF5',
};

const outlawThemeLight = createLightTheme(outlawTheme);
const outlawThemeDark = createDarkTheme(outlawTheme);
const useDarkMode =
  platform === 'OfficeOnline' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <FluentProvider theme={useDarkMode ? outlawThemeDark : outlawThemeLight}>
        <Component
          title={title}
          isOfficeInitialized={isOfficeInitialized}
          documentProperties={documentProperties}
          isSupported={isSupported}
          onInit={(instance) => {
            console.log('about to init for ', instance);
            initFirebase(instance);
          }}
          auth={fireAuth}
        />
      </FluentProvider>
    </AppContainer>,
    document.getElementById('container')
  );
};

/* Render application after Office initializes */
Office.onReady((office) => {
  console.log('office.onready fired!', office);
  isOfficeInitialized = true;
  platform = office.platform;
  isSupported = Office.context.requirements.isSetSupported('WordApi', '1.5');

  //Check for dealID
  Word.run(async (context) => {
    const properties = context.document.properties.customProperties;
    const props = context.document.properties;

    properties.load('key,value');

    await context.sync();

    if (!properties.items || !properties?.items.length) return;

    const dealID = properties.items.find((item) => item.key === 'dealID').value;

    console.info(`This document has a dealID: ${dealID}`);

    documentProperties.existingDealID = dealID;
  });

  initFirebase('app');

  render(App);
});

const getFirebaseApp = () => firebaseApp;
const getFirebaseAuth = () => firebaseAuth;
const getFirebaseDB = () => firebaseDB;
const getFirebaseStorage = () => firebaseStorage;

export { firebaseApp, firebaseAuth, firebaseDB, firebaseStorage };
export { getFirebaseApp, getFirebaseAuth, getFirebaseDB, getFirebaseStorage, fireApp, initAuth, fireAuth, config };

/* Initial render showing a progress bar */
render(Spinner); //render(App) //Maybe show something else until Firebase has initialized

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}
