import * as React from 'react';

import { Button, Input, Label } from '@fluentui/react-components';

const VariableInput = ({ name, value, valueType, onChange, disabled }) => (
  <article>
    <Label htmlFor={name} size="medium">
      {name}
    </Label>
    <div>
      {valueType === 'image' && value?.downloadURL && <img src={value.downloadURL} style={{ width: '100%' }} />}

      {valueType === 'image' && !value?.downloadURL && (
        <Input
          id={name}
          value={disabled ? 'Edit this image on Outlaw' : value}
          defaultValue={disabled ? 'Edit this image on Outlaw' : value}
          onChange={(e) => onChange(name, e.target.value)}
          disabled={disabled}
          style={{ width: '100%' }}
        />
      )}

      {valueType !== 'image' && (
        <Input
          id={name}
          value={value}
          defaultValue={value}
          onChange={(e) => onChange(name, e.target.value)}
          disabled={disabled}
          style={{ width: '100%' }}
        />
      )}
    </div>
  </article>
);

const VariablesTab = ({ variables = [], onSave, onVariableRefresh, saveDialog, children }) => {
  const [values, setValues] = React.useState({});
  const [hasChanges, setHasChanges] = React.useState(false);

  const handleChange = (variable, value) => {
    if (!hasChanges) setHasChanges(true);

    setValues({ ...values, [variable]: value });
  };

  const handleSave = () => {
    setHasChanges(false);
    onSave &&
      onSave(
        values,
        (e) => console.log('done saving!', e),
        (e) => console.log('Oops, there was a problem', e)
      );
  };

  const handleRefreshVariables = () => {
    onVariableRefresh && onVariableRefresh();
  };

  return (
    <div className="container">
      {children}

      <Button onClick={handleRefreshVariables} style={{ width: '100%' }}>
        Refresh variables
      </Button>

      {!!variables.length && (
        <React.Fragment>
          {!hasChanges && saveDialog}
          {variables.map((variable) => (
            <VariableInput
              key={variable.name}
              name={variable?.displayName || variable.name}
              value={variable.value || ''}
              onChange={handleChange}
              disabled={true}
              valueType={variable.valueType}
              //Until editing is supported
            />
          ))}
          {/* Until editing is supported
          <Button
            disabled={!hasChanges}
            onClick={handleSave}
            style={{ ...styles.submit, ...styles.actionButton }}
          >
            Update Variables
          </Button>
          */}
        </React.Fragment>
      )}

      {!variables.length && 'No variables'}
    </div>
  );
};

export default VariablesTab;
