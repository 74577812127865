import * as React from 'react';

import { Combobox, Option, OptionGroup } from '@fluentui/react-components/unstable';

const TemplateMenu = ({
  appearance,
  style,
  placeholder,
  onOptionSelect,
  onReset,
  templates = [],
  selectedTemplate = null,
}) => {
  const [filteredTemplates, setFilteredTemplates] = React.useState([...templates]);

  const getTemplateFromTitle = (title) => templates.find((template) => template.title === title);

  const handleTemplateSearch = (v) => {
    const value = v.trim().toLowerCase();
    const matches = templates.filter((option) => option.title.toLowerCase().includes(value));

    setFilteredTemplates(matches.length ? matches : templates);
  };

  const styles = {
    templateInput: { width: '100%', minWidth: 'auto' },
  };

  return (
    <Combobox
      aria-labelledby="selectTemplate"
      placeholder={placeholder || 'Select a template'}
      freeform
      onInput={(e) => handleTemplateSearch(e.target.value)}
      onOptionSelect={(_e, v) => {
        if (v.optionValue === 'showAll') {
          onReset && onReset();
        } else {
          const template = getTemplateFromTitle(v.optionValue);
          onOptionSelect(template);
        }
      }}
      appearance={appearance}
      size="small"
      style={{ ...style, ...styles.templateInput }}
      value={selectedTemplate?.title}
      spellCheck={false}
    >
      {!!templates.length && (
        <>
          {!!selectedTemplate && (
            <OptionGroup>
              <Option value="showAll" checkIcon={<></>}>
                {placeholder || 'Show All'}
              </Option>
            </OptionGroup>
          )}

          {filteredTemplates.map((option) => (
            <Option key={option.key} checkIcon={<></>}>
              {option.title}
            </Option>
          ))}
        </>
      )}
      {!templates.length && <Option disabled>Loading Templates ...</Option>}
    </Combobox>
  );
};

export default TemplateMenu;
