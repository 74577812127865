import * as React from 'react';

import { Button, Image, Input, Label, Link, makeStyles, mergeClasses } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';

import CONFIG from '@root/Config';

import viewContext from '../contexts/viewContext';

const useStyles = makeStyles({
  root: {
    marginTop: '16px',

    marginBottom: 0,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '16px',
  },
  title: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  input: { width: '100%' },
  button: { width: '100%' },
  link: { color: '#8A8886', cursor: 'pointer' },
});

const DOMAINS = Object.values(CONFIG).map((config) => config.DOMAIN);
const INSTANCES = Object.values(CONFIG).map((config) => config.INSTANCE);

const OutlawInstance = () => {
  const view = React.useContext(viewContext);
  const [instance, setInstance] = React.useState(view.instance);
  const [error, setError] = React.useState(false);

  const handleConfirm = () => {
    const safeInstance = instance.trim().toLowerCase();
    let foundInstance;

    setInstance(safeInstance);

    foundInstance = CONFIG[safeInstance];
    foundInstance && console.log('found directly', safeInstance);

    if (!foundInstance) {
      //Try to parse input as url
      const url = new URL(instance.startsWith('http') ? safeInstance : `https://${instance}`);
      foundInstance = Object.values(CONFIG).find((config) => config.DOMAIN === url.hostname);
      foundInstance && console.log('found by hostname', url.hostname);

      if (!foundInstance) {
        foundInstance = Object.values(CONFIG).find((config) => config.INSTANCE === safeInstance);
        foundInstance && console.log('found by instance name', safeInstance);
      }
    }

    if (!foundInstance) {
      setError(true);
      return;
    }

    view.setInstance(foundInstance.INSTANCE);
    view.removeFromViewStack();
  };

  const styles = useStyles();

  return (
    <div className={mergeClasses('container', styles.root)}>
      <Image src="../../../assets/logo.png" width="86" />
      <h2 className="title">Change Outlaw instance</h2>
      {error && <Alert intent="error">Please enter a valid instance</Alert>}
      <div>
        <Label>Instance name or URL</Label>
        <Input
          value={instance}
          onChange={(e) => {
            setError(false);
            setInstance(e.target.value);
          }}
          onKeyUp={(e) => e.key === 'Enter' && handleConfirm()}
          placeholder="e.g. app or app.getoutlaw.com"
          contentAfter={CONFIG[instance]?.DOMAIN}
          size="small"
          className={styles.input}
          spellCheck={false}
        />
      </div>
      <Button onClick={handleConfirm} appearance="primary">
        Confirm
      </Button>
      <Link
        onClick={() => {
          setError(false);
          setInstance('app');
        }}
        className={styles.link}
      >
        Reset to default (app.getoutlaw.com)
      </Link>
    </div>
  );
};

export default OutlawInstance;
