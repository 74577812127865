import * as React from 'react';

import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { ChevronDown12Regular as ChevronDown, ChevronUp12Regular as ChevronUp } from '@fluentui/react-icons';

import viewContext from '../contexts/viewContext';
// import Auth from '../../Auth';
import { fireAuth as Auth } from '../index';

const styles = {
  topBar: {
    color: '#888',
    padding: '4px 32px 4px 16px',
    borderBottom: '1px solid #dedede',
  },
  columns: { width: '100%', display: 'flex' },
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  triggerBtn: { minWidth: 'initial' },
};

const TopBar = () => {
  const view = React.useContext(viewContext);

  const [menuOpen, setMenuOpen] = React.useState(false);

  const logout = () => {
    view.setLoggedOut(true);
    Auth.logout();
  };
  const { user } = view;

  if (!user) return <section />;

  return (
    <div style={styles.topBar}>
      <div style={styles.columns}>
        <Menu onOpenChange={(_e, data) => setMenuOpen(data.open)} closeOnScroll={true}>
          <MenuTrigger disableButtonEnhancement>
            {(props) => (
              <>
                <Button {...props} appearance="transparent" style={styles.button}>
                  {user?.info.email}
                </Button>
                <Button
                  {...props}
                  appearance="transparent"
                  size="small"
                  style={{ ...styles.button, ...styles.triggerBtn }}
                >
                  {menuOpen ? <ChevronUp /> : <ChevronDown />}
                </Button>
              </>
            )}
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};

export default TopBar;
