const config = {
  INTERCOM: 'fioelmm8',
  FIREBASE: {
    apiKey: 'AIzaSyApsMZZv92S_PtMruecQeYyplYfYlm4v44',
    authDomain: 'vertafore.getoutlaw.com',
    projectId: 'outlaw-vertafore',
    storageBucket: 'outlaw-vertafore.appspot.com',
    messagingSenderId: '911466237480',
    appId: '1:911466237480:web:1412e002ea9150d085ef1d',
    measurementId: 'G-RESRTP097M',
  },
  INSTANCE: 'vertafore',
  DOMAIN: 'vertafore.getoutlaw.com',
  DEFAULT_CONNECTIONS: ['vertafore'],
};

module.exports = config;
