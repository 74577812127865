import * as React from 'react';

import { Button, Label } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';

import documentContext from '../../contexts/documentContext';
import { STEPS } from '../../utils/document';
import FileNameWithIcon from '../FileNameWithIcon';
import TeamMenu from '../TeamMenu';

const FirstStep = () => {
  const {
    fileName,
    selectedTeam,
    teamUpdated,
    getDealsFromState,
    setCurrentStep,
    isSaving,
    selectTemplateStep,
    user,
    errorMsg,
    teams,
  } = React.useContext(documentContext);

  const styles = {
    fullWidth: {
      width: '100%',
    },
    errorMsg: {
      color: '#EE6662',
    },
  };

  return (
    <div className="container">
      <h2>Save document to repository</h2>

      <FileNameWithIcon filename={fileName} />
      <TeamMenu
        selectedTeam={selectedTeam}
        onOptionSelect={teamUpdated}
        style={styles.fullWidth}
        disabled={isSaving}
        size="small"
        teams={teams}
      />

      {errorMsg && <Alert intent="error">{errorMsg}</Alert>}

      <Label>Select an option below</Label>

      <section>
        <Button
          disabled={isSaving || !selectedTeam || (selectedTeam && !selectedTeam.userCanEdit)}
          onClick={() => {
            getDealsFromState({ template: null });
            setCurrentStep(STEPS.update);
          }}
          style={styles.fullWidth}
        >
          Update an existing document on Outlaw
        </Button>
      </section>
      <section>
        <Button
          disabled={isSaving || !selectedTeam || (selectedTeam && !selectedTeam.userCanCreateDoc)}
          onClick={() => selectTemplateStep()}
          style={styles.fullWidth}
        >
          Save file as new document
        </Button>
      </section>

      {selectedTeam && !selectedTeam.userCanCreateDoc && (
        <section style={styles.errorMsg}>
          This user account ({user.info.email}) been restricted by the team owner of {selectedTeam?.info.name}. User
          role must have New documents set as active.
        </section>
      )}
    </div>
  );
};

export default FirstStep;
