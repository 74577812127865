import { ACCEPTED_TYPES } from '@getoutlaw/outlaw-core/models/Attachment';
import { DEAL_TYPE } from '@getoutlaw/outlaw-core/models/Deal';
import { arrayToBase64 } from '@getoutlaw/outlaw-core/utils';

import API from '@root/ApiClient';

// import Fire from '@root/Fire';
import { fireApp as Fire } from '../index';
import { getAllSlices } from '../utils/file';

/* global Word */

export const STEPS = {
  save: 'SAVE',
  uploaded: 'UPLOADED',
  selectTemplate: 'SELECT_TEMPLATE',
  saving: 'SAVING',
  update: 'UPDATE',
};

export const createOrUpdateDocument = async ({
  dealID,
  user,
  selectedTeam,
  newDocument,
  selectedTemplate,
  title,
  onStepChange,
  onDocStepChange,
  onUploadedDocument,
  onError,
}) => {
  onStepChange(STEPS.saving);
  onDocStepChange('Preparing document');

  await Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 100000 }, async (result) => {
    console.log('---getFileAsync', result);
    const newFile = result.value;
    console.log('createOrUpdateDocument.newFile', newFile);
    let data = null;

    onDocStepChange(`Parsing document, ${result.status}`);

    if (result.status == Office.AsyncResultStatus.Succeeded) {
      onDocStepChange('Gathering Document slices');
      let newDealID;

      try {
        await getAllSlices(newFile).then(async (result) => {
          onDocStepChange('Finished processing Document');
          data = new Uint8Array(result.Data);
        });

        onDocStepChange('Creating document from Word file');

        const createdDeal = await API.call('createDealFromDocument', {
          dealID: dealID || null,
          userID: newDocument.attribution || user.id,
          teamID: selectedTeam.teamID,
          fileType: ACCEPTED_TYPES.WORD.mime,
          dealStatus: newDocument.status,
          lastModified: newDocument.dateValue,
          versionDescription: newDocument.description,
          dealType: DEAL_TYPE.EXTERNAL_WORD,
          title: title || 'Untitled',
          versionDescription: newDocument.description,
          templateDealID: selectedTemplate?.dealID || null,
          data: arrayToBase64(data),
          importJSON: null,
        });

        if (createdDeal.success) {
          newDealID = createdDeal.dealID;

          onDocStepChange(`Finished creating document`);

          onDocStepChange(`Fetching Deal, ${newDealID}`);

          const newDeal = await Fire.getDeal(newDealID);

          onDocStepChange(`Got Deal, ${newDealID}`);

          onUploadedDocument(newDeal);

          onDocStepChange('Done!');
          onStepChange(STEPS.uploaded);

          setTimeout(() => onDocStepChange(null), 1000);

          newFile.closeAsync();
        } else {
          console.log('doc create error a');
          onError(result.error.message);
        }
      } catch (e) {
        console.log('doc create error b', e);
        onError(`Unable to reach Outlaw, please check your connection and try again. ${e}`);
      }
    } else {
      console.log('doc create error c', result.error);
      onError(`Document parsing failed: ${result.error.message}`);
    }
  });
};

export const getLastSaveTime = () =>
  Word.run(async (context) => {
    var document = context.document;
    document.properties.load('lastSaveTime');

    await context.sync();

    return document.properties.lastSaveTime;
  });
