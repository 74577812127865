import * as React from 'react';

import { Spinner } from '@fluentui/react-components';

import Auth from '@root/Auth';

const loginViaDomain = async (domain) => {
  console.log('loginViaDomain', domain);
  Auth.signInWithPopup(domain).then((r) => {
    console.log('loginViaDomain ... done?', r);
  });
};

const LoadAuth = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const domain = urlParams.get('domain') ?? false;
  const styles = {
    marginTop: '16px',
  };

  React.useEffect(() => {
    console.log('useEffect');
  }, [domain]);

  if (domain) {
    console.log('yes, domain');
    loginViaDomain(domain);
  }

  return (
    <div style={this.styles.root}>
      <center>
        <h2>Logging in {domain && 'via ' + domain}</h2>
        <Spinner />
      </center>
    </div>
  );
};

export default LoadAuth;
