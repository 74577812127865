import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components';

const DialogAlert = ({ title, isVisible, onChange, onClick, buttonText = 'Ok', cancelText, children }) => {
  const styles = {
    surface: { maxWidth: 'calc(100vw - 40px)' },
  };

  return (
    <Dialog open={isVisible} /*onOpenChange={(_event, data) => onChange(data.open)}*/ modalType="alert">
      <DialogSurface style={styles.surface}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            {cancelText && (
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary" onClick={() => onChange(false)}>
                  {cancelText}
                </Button>
              </DialogTrigger>
            )}
            <Button
              onClick={() => {
                onClick && onClick();
                // onChange(false);
              }}
              appearance="primary"
            >
              {buttonText}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DialogAlert;
