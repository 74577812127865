import * as React from 'react';

import { DealRole } from '@getoutlaw/outlaw-core/enums';

import API from '@root/ApiClient';

import Version from './Version';

const DealVersion = ({
  index,
  isLatest,
  deal,
  onClick,
  link,
  workflowColor,
  onError,
  user,
  version,
  children,
  title,
  filename,
  ...props
}) => {
  if (!deal) return null;

  const created = parseInt(version?.dateCreated) || version?.dateCreated;
  const updated = parseInt(deal.updated) || deal.updated;

  const createdDate = new Date(created || updated);
  const dealUser = deal.users.find((u) => u.uid === user.id);

  const dealUserCanEdit = dealUser && [DealRole.EDITOR, DealRole.OWNER, DealRole.PROPOSER].includes(dealUser.role);
  const canEditTeam = user.canEditTeam(deal.sourceTeam);

  const canEdit = dealUserCanEdit || canEditTeam; /*&&
    ['accepted', 'owned'].includes(dealUser.inviteStatus)*/

  const noEditAccessMsg = `Your user permission ${
    dealUser?.role ? `(${dealUser.role})` : ''
  } does not allow you to edit or update this document.`;

  const handleClick = async () => {
    let allowEdit = canEdit;

    if (!dealUserCanEdit && canEditTeam) {
      allowEdit = await API.call('observe', { dealID: deal.dealID, uid: user.id });
    }

    allowEdit ? onClick && onClick(version?.key) : onError && onError(noEditAccessMsg);
  };

  return (
    <Version
      key={deal.dealID}
      index={index}
      dealID={deal.dealID}
      isLatest={isLatest}
      status={version?.dealStatus || deal.status}
      title={title} //DealRecord or Version
      filename={filename}
      description={version?.description}
      workflowColor={workflowColor}
      parties={deal.parties}
      onClick={handleClick}
      link={link}
      createdDate={createdDate}
      canEdit={canEdit}
      origin={version?.origin || deal.dealType}
      // origin={!!deal.versions.length ? deal.versions.find((v) => v.id === version).origin : deal.dealType}
      {...props}
    >
      {children}
    </Version>
  );
};

export default DealVersion;
