import * as React from 'react';

import { Tab, TabList } from '@fluentui/react-components';

const DocumentInfo = ({ document, tabs = {}, topSection = null }) => {
  const { name } = document.info;
  const versions = document.versions;
  const defaultTab = 'versions';

  const [documentTab, setDocumentTab] = React.useState(defaultTab);

  const styles = {
    root: {
      backgroundColor: '#f3f3f3',
      padding: '16px 16px 4px 16px',
    },
    tabs: {
      backgroundColor: '#f3f3f3',
    },
    fileName: {
      fontWeight: 600,
      marginBottom: '4px',
    },
    tabContent: {
      padding: '16px 0px',
    },
  };

  return (
    <React.Fragment>
      <div>
        <div style={styles.root}>{topSection}</div>
        <TabList
          selectedValue={documentTab}
          onTabSelect={(_e, data) => setDocumentTab(data.value)}
          appearance="transparent"
          style={styles.tabs}
        >
          {Object.keys(tabs).map((key, i) => (
            <Tab key={key} value={key}>
              {tabs[key].title}
            </Tab>
          ))}
        </TabList>
      </div>
      <section style={styles.tabContent}>{tabs[documentTab]?.content}</section>
    </React.Fragment>
  );
};

export default DocumentInfo;
