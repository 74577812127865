const config = {
  FIREBASE: {
    apiKey: 'AIzaSyBEdVASTMtC4BVNqKasmjQjPARD49wBJqE',
    //authDomain: "outlaw-cla.firebaseapp.com",
    authDomain: 'cla.getoutlaw.com',
    projectId: 'outlaw-cla',
    storageBucket: 'outlaw-cla.appspot.com',
    messagingSenderId: '505427671512',
    appId: '1:505427671512:web:2dba40aee914524b175aad',
    measurementId: 'G-KW1QVJXPMQ',
  },
  INSTANCE: 'cla',
  DOMAIN: 'cla.getoutlaw.com',
};

module.exports = config;
