/* eslint-disable no-console */
import firebase from 'firebase/compat/app';

import API from '@root/ApiClient';

// import Fire from '@root/Fire';
import { fireApp as Fire } from './taskpane/index';

export const PROVIDERS = {
  GOOGLE: {
    id: 'google.com',
    name: 'Google',
    logo: '/assets/svg/login-google.svg',
  },
  MICROSOFT: {
    id: 'microsoft.com',
    name: 'Microsoft',
    logo: '/assets/svg/login-microsoft.svg',
  },
};

class OutlawAuth {
  init(fireAuth, authChange, authChangeInProgress) {
    this.fireAuth = fireAuth;
    this.authChange = authChange;
    this.authChangeInProgress = authChangeInProgress;
    this.anonEmail = null;

    // add a handler to firebase auth state which will be called anytime auth state changes
    // including on first load/initialization
    this.fireAuth.onAuthStateChanged(async (account) => {
      console.log('[AUTH] User has changed. Going through verifications steps.');
      await this.authChangeInProgress();

      if (account) {
        console.log('[AUTH] User is authenticated; checking for profile');

        // Load user profile data ONCE, just to see if it's a new user or not
        // Note this will NOT listen for realtime updates; that is done in App
        const user = await Fire.getUser(account.uid);
        if (!user) {
          console.log('[AUTH] New user signup! Registering...');
          const { uid, email, photoURL } = account;

          try {
            await API.call('register', { uid, email, photoURL });
            await API.call('indexUser');
            console.log('[AUTH] New user successfully registered!');
            await authChange(account, this.anonEmail);
          } catch (err) {
            console.log('[AUTH] Registration error', err);
            await authChange(account, this.anonEmail);
          }
        } else {
          console.log('[AUTH] Existing user found; proceeding with app startup');
          await authChange(account, this.anonEmail);
        }
      } else {
        console.log('[AUTH] User not authenticated');
        this.anonEmail = null;
        await authChange(null, null);
      }
    });

    this.verifySignInEmailLink();
  }

  async verifySignInEmailLink() {
    // Confirm the link is a sign-in with email link.
    if (this.fireAuth.isSignInWithEmailLink(window.location.href)) {
      await this.authChangeInProgress();
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      console.log('verifySignInEmailLink() : Email in storage [emailForSignIn]', email);
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      this.fireAuth
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          console.log('verifySignInEmailLink() : Sign In successful!');
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  // the onAuthStateChanged handler above will fire on a new successful provider login
  // so we don't actually need to handle it here.
  // instead, this is for "existing account" errors" because that is the only path
  // to link an additional provider to an existing account
  // https://firebase.google.com/docs/auth/web/account-linking
  // this is used in a few places, each with different callbacks that can be specified here:
  // 1. on /contracts when anon user creates a contract (by accessing page) then logs in with an existing account
  // 2. on /account/profile (connected logins) to add a linked provider
  // 3. and in the top-level App to handle a case where a user logs in with another provider
  //    but is NOT currently logged in with the first (so we need to notify)
  handleLoginRedirect({ diffCred, linkAccounts, mergeAnonymous }) {
    this.fireAuth
      .getRedirectResult()
      .then(({ user, credential }) => {
        // normal case, unauthed user logs in and lands here
        // again no callback is needed here because the onAuthStateChanged handler above will fire
        if (user && credential) console.log('[AUTH] 3rd-party login successful');
      })
      .catch(async (error) => {
        console.error(error);
        console.log(
          '[AUTH] handleLoginRedirect() ERROR : ',
          `email=${error?.email} credential=${JSON.stringify(error?.credential)}`
        );
        const currentUser = this.fireAuth.currentUser;

        console.log('[AUTH] ERROR error.customData : ', JSON.stringify(error?.customData));
        console.log('[AUTH] ERROR error.customData.email : ', error?.customData?.email);
        console.log('[AUTH] ERROR error.customData.credential : ', error?.customData?.credential);
        console.log('[AUTH] ERROR error.customData.credential.JSON : ', JSON.stringify(error?.customData?.credential));

        const currentProviders = error.email ? await this.fireAuth.fetchSignInMethodsForEmail(error.email) : [];
        console.log('currentProviders : ', JSON.stringify(currentProviders));

        if (error.code === 'auth/account-exists-with-different-credential') {
          console.log('[AUTH] => auth/account-exists-with-different-credential');
          //here, user is unauthed and attempted to login, but already has another account
          //e.g., had established an account via Microsoft login, then tries to login with Google instead
          //but current user is not authed, so we can't safely link accounts
          //and need to show a login
          if (!currentUser && error.credential) {
            console.log('[AUTH] => auth/account-exists-with-different-credential => diffCred()');
            if (typeof diffCred === 'function') diffCred(currentProviders, error.credential);
          }
          //here the current user already IS logged in with the first provider
          //so we can safely attach additional logins
          //this case is accessed from /account/profile page to "Connect" accounts
          else if (currentUser && error.credential) {
            console.log('[AUTH] => auth/account-exists-with-different-credential => linkAccounts()');
            if (typeof linkAccounts == 'function') linkAccounts(currentUser, error.credential);
          }
        } else if (error.code === 'auth/credential-already-in-use') {
          if (currentUser && currentUser.isAnonymous) {
            console.log('[AUTH] => auth/credential-already-in-use => mergeAnonymous()');
            mergeAnonymous(currentUser, error.credential);
          } else {
            console.log('[AUTH] => auth/credential-already-in-use => do nothing');
            //not sure how we'd ever get here; user basically sequentially switched from one auth'd user to another
            //but got an error. not sure if it's even possible
          }
        } else {
          //other login error not related to linking accounts
          console.log('[AUTH] Login error:', error);
        }

        // We need to this at the end if we want to gather the logs that were printed before catching the exception.
        window.Sentry.captureException(error);
      });
  }

  anonLogin(anonEmail) {
    this.anonEmail = anonEmail;
    return this.fireAuth.signInAnonymously().catch((error) => console.error(error));
  }

  async emailLogin(email, password, success, failure) {
    const currentUser = this.fireAuth.currentUser;

    // In case we're logging in from an already logged-in anonymous (guest) account,
    // we need to grab the token right now for later merging
    // Depending on where we are in the app, e.g., on DealView, signing in may trigger a Firebase error
    // because we're now a different user, and that seems to halt execution, making it unreliable to attempt merging here
    // So instead, pass the former token back via success() and let the UI handle it (see Login, UserMenu and MergeAccounts)
    let fromToken;
    if (currentUser) fromToken = await Fire.token();

    try {
      const realUser = await this.fireAuth.signInWithEmailAndPassword(email, password);

      if (currentUser && currentUser.isAnonymous && realUser) {
        success(fromToken);
      } else {
        success();
      }
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case 'auth/wrong-password':
          failure('Incorrect Password.');
          break;
        case 'auth/user-not-found':
          failure('Account not found.');
          break;
        default:
          failure(error.message);
          break;
      }
    }
  }

  async signup(email, password) {
    // If user is already logged in as guest,
    // attempt new account creation and if it works, link credential to "upgrade" anonymous account to email/pw
    // https://firebase.google.com/docs/auth/web/anonymous-auth
    const current = this.fireAuth.currentUser;
    let newAccount;
    if (current && current.isAnonymous) {
      console.log(`Attempting upgrade for guest user: `, current.uid);
      const credential = firebase.auth.EmailAuthProvider.credential(email, password);
      // linkWithCredential API: https://firebase.google.com/docs/reference/js/firebase.User.html#linkwithcredential
      try {
        //linkWithCredential resolves with a UserCredential object that wraps the User object (which we call "account")
        const newCred = await current.linkWithCredential(credential);
        newAccount = newCred.user;
        console.log('[AUTH] Anonymous account successfully upgraded', newAccount);

        await this.verify(newAccount);
        console.log('[AUTH] Email verification request sent', newAccount.email);

        // Because this was an upgrade ("linking" a provider to an existing (guest) account),
        // authChange doesn't actually fire because it's the same authed user who is still logged in
        // but we want the app to handle it and update accordingly, so call it explicitly
        await this.authChange(newAccount);

        return Promise.resolve(newAccount);
      } catch (err) {
        console.log('[AUTH] Error upgrading anonymous account', err);
        if (err.code === 'auth/email-already-in-use') {
          return Promise.reject('Account already exists!');
        } else {
          return Promise.reject(err.message);
        }
      }
    }
    // If current user is NOT logged in (normal case), automatically create
    // This path WILL trigger authChange because it also logs user in
    else {
      try {
        await this.fireAuth.createUserWithEmailAndPassword(email, password);
        newAccount = this.fireAuth.currentUser;
        console.log('[AUTH] New email/pw account successfully created', newAccount);

        await this.verify(newAccount);
        console.log('[AUTH] Email verification request sent', newAccount.email);

        return Promise.resolve(newAccount);
      } catch (err) {
        if (err.code == 'auth/email-already-in-use') {
          return Promise.reject('Account already exists!');
        } else {
          return Promise.reject(err.message);
        }
      }
    }
  }

  /*
   ** I'm not sure why, but this stopped working in Word desktop.  It still works on Web
   ** This is possibly due to a recent Auth change around CORS
   */

  async signInWithPopup(domain) {
    const provider = this.getServiceProvider(domain);
    console.log({ provider });
    if (!provider) throw new Error(`Provider not found for ${domain}`);

    const user = await this.fireAuth.signInWithPopup(provider).catch((error) => console.error(error));

    console.log({ user });
  }

  async signInWithRedirect(domain) {
    console.log('[Auth] signInWithRedirect a');
    const provider = this.getServiceProvider(domain);
    console.log('[Auth] signInWithRedirect b');
    if (!provider) throw new Error(`Provider not found for ${domain}`);
    console.log('[Auth] signInWithRedirect c');
    firebase.auth().signInWithRedirect(provider);
    console.log('[Auth] signInWithRedirect d');
  }

  //rename to indicate these send email requests
  verify(accountToVerify) {
    return accountToVerify.sendEmailVerification();
  }

  resetPassword(email, success, failure) {
    this.fireAuth
      .sendPasswordResetEmail(email)
      .then(success)
      .catch((error) => {
        // An error happened.
        console.log(error);
        if (error.code == 'auth/user-not-found') failure('Account not found.');
        else failure(error.message);
      });
  }

  changePassword(actionCode, email, newPassword, success, failure) {
    this.fireAuth
      .confirmPasswordReset(actionCode, newPassword)
      .then(() => {
        success();
      })
      .catch((error) => {
        failure(error.message);
      });
  }

  handleVerifyEmail(actionCode) {
    return this.fireAuth.applyActionCode(actionCode);
  }

  verifyPasswordResetCode(actionCode) {
    return this.fireAuth.verifyPasswordResetCode(actionCode);
  }

  getServiceProvider(service) {
    let provider;

    switch (service) {
      case 'google.com':
        provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account',
        });
        break;
      case 'microsoft.com':
        provider = new firebase.auth.OAuthProvider('microsoft.com');
        break;
      default:
        provider = null;
        break;
    }

    return provider;
  }

  async login(service, credential, callback, loginMethod) {
    console.log('Auth.login');
    const provider = this.getServiceProvider(service);
    console.log('Auth.login provider', provider, this.fireAuth);
    const current = this.fireAuth.currentUser;
    console.log('Auth.login a');
    if (credential) {
      console.log('Auth.login b');
      const userCredential = await this.fireAuth.signInWithCredential(credential);
      if (typeof callback === 'function') callback(userCredential);
      return Promise.resolve(userCredential);
    }
    console.log('Auth.login c');
    //this needs to be after the prior line so that account merging (via credential) works!
    if (!provider) return;
    console.log('Auth.login d');
    // set custom OAuth parameters to pass in the OAuth request
    // https://openid.net/specs/openid-connect-core-1_0.html#AuthorizationEndpoint
    provider.setCustomParameters({
      prompt: 'select_account', // enforce selection of account during login
    });
    console.log('Auth.login e');
    if (current && current.isAnonymous) {
      console.log('Auth.login f');
      //anonymous user already exists -- link account!
      //this will succeed if the user logs in and creates a new account
      //if the account already exists, upon post-login redirect it will hit the merge path above
      //see auth/credential-already-in-use error case in handleLoginRedirect()
      await current.linkWithRedirect(provider);
    }

    //normal case, unauth'd user just logging in as normal
    //or, user IS logged in but is logging in again
    //(shouldn't ever really get here because user should already be logged in
    //and shouldn't be able to access login again)
    //but handle like normal anyway to prevent a UX dead-end
    else {
      console.log('Auth.login g');
      console.log('Auth.login about to signInWithRedirect');
      await this.fireAuth.signInWithRedirect(provider);
      console.log('Auth.login z');
    }
  }

  async logout() {
    try {
      await API.call('revokeRefreshTokens');
      await this.fireAuth.signOut();
    } catch (err) {
      console.log(err);
    }
    await this.authChange(null);
    return Promise.resolve();
  }
}

const Auth = new OutlawAuth();
export default Auth;
export { OutlawAuth };
