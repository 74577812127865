import CreateAccount from '../components/CreateAccount';
import LoadAuth from '../components/LoadAuth';
import LoginForm from '../components/LoginForm';
import OutlawInstance from '../components/OutlawInstance';
import SyncDocument from '../components/SyncDocument';

export const loggedInRoute = {
  path: 'sync-document',
  component: SyncDocument,
};

const routes = [
  // {
  //   path: '/',
  //   component: Login,
  //   protected: false,
  // },
  {
    path: '/', //path: 'login-form',
    component: LoginForm,
  },
  {
    path: 'instance', //path: 'login-form',
    component: OutlawInstance,
  },
  {
    path: 'login-create',
    component: CreateAccount,
  },
  {
    path: 'load-auth',
    component: LoadAuth,
  },
  loggedInRoute,
];

export default routes;
