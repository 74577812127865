import * as React from 'react';

import { Tooltip } from '@fluentui/react-components';

import { useIsOverflow } from '../utils/hooks';

const styles = {
  root: {
    display: 'inline-block',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const SingleLine = ({ content, children, style }) => {
  const ref = React.useRef();
  const [visible, setVisible] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);

  useIsOverflow(ref, (isOverflowFromCallback) => setEnabled(isOverflowFromCallback));

  return (
    <Tooltip
      content={content}
      relationship="label"
      withArrow={false}
      visible={visible && enabled}
      onVisibleChange={(_ev, data) => setVisible(data.visible)}
      positioning="above"
      inverted={true}
    >
      <div style={{ ...styles.root, ...style }} ref={ref}>
        {children}
      </div>
    </Tooltip>
  );
};

export default SingleLine;
