const config = {
  FIREBASE: {
    apiKey: 'AIzaSyC62F4GaA6DOIPhV_UGT1zZIpnPpvcAi9w',
    authDomain: 'app.getoutlaw.com',
    databaseURL: 'https://project-8611684588468796195.firebaseio.com',
    storageBucket: 'gs://project-8611684588468796195.appspot.com/',
    projectId: 'project-8611684588468796195',
    messagingSenderId: '1069881916351',
    appId: '1:1069881916351:web:75f52fa297769871a7ff0a',
  },
  INSTANCE: 'app',
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  DOMAIN: 'app.getoutlaw.com',
};

module.exports = config;
