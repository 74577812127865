import * as React from 'react';

import { Link } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { ChevronLeft12Regular as BackIcon, Open16Filled as OpenIcon } from '@fluentui/react-icons';
import { ValueType, VariableType } from '@getoutlaw/outlaw-core/models/Variable';

import documentContext from '../../contexts/documentContext';
// import Fire from '@root/Fire';
import { fireApp as Fire } from '../../index';
import { openInNewTab } from '../../utils/browser';
import { getDealUrl } from '../../utils/deals';
import { STEPS } from '../../utils/document';
import DocumentInfo from '../DocumentInfo';
import EditDocumentTitle from '../EditDocumentTitle';
import InlineLink from '../InlineLink';
import Party from '../Party';
import VariablesTab from '../VariablesTab';
import VersionsTab from '../VersionsTab';

/* global Office */

const SaveUploadedStep = () => {
  const {
    setCurrentStep,
    newFileName,
    setNewFileName,
    fileName,
    uploadedDocument,
    newDocument,
    selectedTemplate,
    createOrUpdateDocumentFromState,
    handleSaveVariables,
    alertMsg,
    errorMsg,
  } = React.useContext(documentContext);

  console.log('<SaveUpdateStep />');

  const [refreshedVars, setRefreshedVars] = React.useState(null);

  const styles = {
    link: {
      display: 'flex',
      padding: 0,
      margin: '0px 0 16px 0',
      fontWeight: 500,
      fontSize: '12px',
    },
    openIcon: {
      padding: '0 8px',
    },
    backBtn: {
      margin: 0,
      padding: 0,
    },
    backIcon: {
      minWidth: 'unset',
      width: '1em',
      padding: 0,
      marginBottom: '-1px',
    },
  };

  const filterVars = (vars) =>
    vars.filter(
      (v) =>
        [VariableType.SIMPLE].includes(v.type) &&
        ![ValueType.TABLE].includes(v.valueType) &&
        v.name.split('.').length === 1
    );

  const displayVars = uploadedDocument?.variables ? filterVars(Object.values(uploadedDocument.variables)) : null;
  const variables = displayVars || selectedTemplate?.variables;

  const sortAndFilterVars = (vars) =>
    filterVars(vars).sort((a, b) =>
      (a.displayName ?? a.name).toLowerCase() > (b.displayName ?? b.name).toLowerCase() ? 1 : -1
    );

  const handleVariableRefresh = async () => {
    // I don't love this, but if we refresh the entire deal we re-render the entire chain
    const deal = await Fire.getDeal(uploadedDocument.dealID);
    const vars = filterVars(Object.values(deal.variables));

    setRefreshedVars(vars);
    // refreshUploadedDocument && refreshUploadedDocument();
  };

  const onCancel = async () => {
    await setCurrentStep(!!uploadedDocument ? STEPS.update : null);
    // setSelectedTeam(null);
  };

  const TopSection = () => (
    <>
      <div>
        <Link appearance="subtle" size="small" onClick={onCancel} style={styles.backBtn}>
          <BackIcon style={styles.backIcon} /> Back
        </Link>
      </div>
      <EditDocumentTitle
        title={uploadedDocument?.name || newFileName}
        defaultTitle={fileName || 'Untitled'}
        onUpdate={(v) => {
          setNewFileName(v);
        }}
        editable={!uploadedDocument?.versions}
      />

      {uploadedDocument && (
        <>
          {uploadedDocument?.parties.map((party, i) => (
            <Party key={party.partyId + i.toString()} party={party} />
          ))}

          <InlineLink
            onClick={() => {
              const url = getDealUrl(uploadedDocument.dealID) + '?panel=users';
              openInNewTab(url);
            }}
            icon={<OpenIcon />}
            iconPosition="after"
            style={styles.link}
          >
            Manage parties
          </InlineLink>
        </>
      )}
    </>
  );

  return (
    <React.Fragment>
      <DocumentInfo
        document={{ info: { name: newFileName || fileName }, versions: [] }}
        tabs={{
          versions: {
            title: 'Version history',
            content: (
              <VersionsTab
                deal={uploadedDocument}
                versions={uploadedDocument?.versions || {}}
                saveNewVersion={(incomingDocument) => {
                  console.log('saveNewVersion()', { incomingDocument });
                  createOrUpdateDocumentFromState({ incomingDocument, dealID: uploadedDocument?.dealID });
                }}
                onCancel={onCancel}
                hasChanges={true}
                startOpen={!uploadedDocument || errorMsg}
              />
            ),
          },
          ...(uploadedDocument !== null && {
            variables: {
              title: 'Variables',
              content: (
                <VariablesTab
                  variables={sortAndFilterVars(refreshedVars || variables)}
                  onVariableRefresh={handleVariableRefresh}
                  onSave={handleSaveVariables}
                  saveDialog={
                    alertMsg && (
                      <Alert intent="info" style={{ marginBottom: '1rem' }}>
                        {alertMsg}
                      </Alert>
                    )
                  }
                >
                  {!uploadedDocument && <Alert intent="info">Variables are read-only in Word</Alert>}

                  {uploadedDocument && (
                    <>
                      <Alert intent="info" style={{ marginBotton: '0' }}>
                        Edit variable values in&nbsp;
                        <InlineLink
                          onClick={() => {
                            const url = getDealUrl(uploadedDocument.dealID) + '?panel=elements';
                            openInNewTab(url);
                          }}
                          icon={<OpenIcon />}
                          target="_blank"
                        >
                          Outlaw
                        </InlineLink>
                      </Alert>
                    </>
                  )}
                </VariablesTab>
              ),
            },
          }),
        }}
        topSection={<TopSection />}
      />
    </React.Fragment>
  );
};

export default SaveUploadedStep;
