import * as React from 'react';

import { Button, Label } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';

import documentContext from '../../contexts/documentContext';
import { STEPS } from '../../utils/document';
import TemplateMenu from '../TemplateMenu';

const TemplateStep = () => {
  const {
    selectedTeam,
    errorMsg,
    setSelectedTemplate,
    selectedTemplate,
    saveWorkflow,
    setErrorMsg,
    setCurrentStep,
    templates,
    fetchTemplateData,
  } = React.useContext(documentContext);

  return (
    <div className="container">
      <h2>Select document template</h2>
      {errorMsg && <Alert intent="error">{errorMsg}</Alert>}
      <div>
        <Label id="selectTemplate">Template</Label>
        <TemplateMenu
          templates={templates}
          selectedTemplate={selectedTemplate}
          onOptionSelect={(template) => setSelectedTemplate(template)}
          onReset={() => {
            setSelectedTemplate(null);
          }}
        />
      </div>

      <Button
        onClick={async () => {
          await fetchTemplateData({
            team: selectedTeam,
            template: selectedTemplate,
            onFetch: saveWorkflow,
          });
          setCurrentStep(STEPS.save);
        }}
        disabled={!selectedTemplate}
        appearance="primary"
      >
        Continue
      </Button>

      <Button
        onClick={() => {
          setErrorMsg(null);
          setCurrentStep(null);
        }}
      >
        Back
      </Button>
    </div>
  );
};

export default TemplateStep;
