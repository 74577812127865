import * as React from 'react';

import { Button, Divider, Label, Link, Radio, RadioGroup, Textarea } from '@fluentui/react-components';
import { Alert, Combobox, Dropdown, Option } from '@fluentui/react-components/unstable';
import { Square16Filled as SquareIcon, ArrowSync20Filled as SyncIcon } from '@fluentui/react-icons';
import { tokens } from '@fluentui/react-theme';

import documentContext from '../contexts/documentContext';
import { openInNewTab } from '../utils/browser';
import { getBaseUrl, getLatestVersion } from '../utils/deals';
import { getLastSaveTime } from '../utils/document';
import { addFileExtension } from '../utils/file';
import { checkDate, getPrettyDateTime } from '../utils/time';
import DateTimePicker from './DateTimePicker';
import DealVersion from './DealVersion';
import DialogAlert from './DialogAlert';

/* global Word */

const VersionsTab = ({ deal, versions, saveNewVersion, onCancel, hasChanges, startOpen }) => {
  const {
    selectedTeam,
    user,
    selectedTemplateData,
    newDocument: newContextDocument,
    setNewDocument: setContextDocument,
    errorMsg,
  } = React.useContext(documentContext);

  console.log('<VersionsTab />', deal);

  const dealUsers = deal?.users || [{ dealUserID: user.id, ...user.info }];

  const hasRequiredFields = () =>
    !!newDocument.status && !!newDocument.attribution && !!newDocument.date && !dateValidationMsg;

  const versionCount = Object.keys(versions).length;
  const sortedVersions = React.useCallback(
    Object.values(versions).sort((a, b) => parseInt(b.dateCreated) - parseInt(a.dateCreated)),
    [versions]
  );
  const minDateString = parseInt(sortedVersions[sortedVersions.length - 1]?.dateCreated) || null;

  const [newDocument, setLocalNewDocument] = React.useState({ ...newContextDocument });
  const [matchingAttributions, setMatchingAttributions] = React.useState([...dealUsers]);
  const [dateValidationMsg, setDateValidationMsg] = React.useState(false);
  const [lastSaveTime, setLastSaveTime] = React.useState(new Date());
  const [hasMatchingTimestamp, setHasMatchingTimestamp] = React.useState(false);
  const [showSaveSettings, setShowSaveSettings] = React.useState(startOpen);
  const [selectedAttribution, setSelectedAttribution] = React.useState(null);
  const [showSaveDialog, setShowSaveDialog] = React.useState(versionCount === 0 || hasChanges);
  const [showVersionDialog, setShowVersionDialog] = React.useState(false);

  //Set selected to current user
  React.useEffect(() => {
    setSelectedAttribution(getDealUserDisplay(dealUsers.find((dealUser) => dealUser.dealUserID === user.id)));
  }, []);

  //Get last save time and set up any error states
  React.useEffect(() => {
    getLastSaveTime().then((lastSaveTime) => {
      setLastSaveTime(lastSaveTime);
    });

    let hasMatch = false;

    if (newDocument?.dateValue) {
      hasMatch = sortedVersions.some(
        (v) => nearestMinute(parseInt(v.dateCreated)) === nearestMinute(newDocument.dateValue)
      );
    }

    setHasMatchingTimestamp(hasMatch);

    if (hasMatch && newDocument?.date === 'last-modified') {
      setShowVersionDialog(true);
      // setNewDocument({ ...newDocument, date: 'now', dateValue: null });
    }
  }, [newDocument]);

  //Do a date check
  React.useEffect(() => {
    console.log('newContextDocument changed');
    if (newDocument?.dateValue && newDocument?.date !== 'now') {
      checkDate(new Date(minDateString), new Date(newDocument.dateValue), setDateValidationMsg);
    }
  }, [newDocument]);

  const setNewDocument = (newDoc) => {
    console.log('setNewDocument', newDoc);
    setLocalNewDocument({ ...newDoc });
    // setContextDocument(newDoc);
  };

  const styles = {
    root: {
      paddingTop: '0.5rem',
      marginBottom: '1rem',
    },
    grow: {
      flexGrow: 2,
    },
    label: {
      flexGrow: 2,
      fontSize: '16px',
    },
    button: {
      padding: 0,
      textDecoration: 'underline',
      textAlign: 'right',
      minWidth: 0,
    },
    split: { display: 'flex', gap: '10px', paddingRight: '10px' },
    fluidInput: {
      minWidth: 'auto',
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    option: { maxWidth: 'calc(100% - 1rem)' },
    column: { flex: '0 0 50%', width: 0 },
    actions: { marginBottom: '2rem' },
    actionButton: { width: '100%' },
    submit: {
      backgroundColor: tokens.colorBrandForegroundLink,
      color: '#fff',
    },
    stepName: {
      maxWidth: 'calc(100% - 1rem)',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    attribution: {
      callout: { maxHeight: 200, overflowY: 'scroll' },
    },
    dateValidationMsg: { marginTop: '1rem', whiteSpace: 'pre-line' },
    versionLabel: { paddingTop: '16px', paddingBottom: '16px' },
  };

  const hanldeAttributionSearch = (e) => {
    const value = e.target.value.trim().toLowerCase();
    const matches = dealUsers.filter((option) => {
      return option.email.includes(value) || option.fullName.toLowerCase().includes(value);
    });

    setMatchingAttributions(matches.length > 0 ? matches : dealUsers);
  };

  const saveAndUpdateDoc = async () => {
    await Word.run(async (context) => {
      // Create a proxy object for the document.
      const thisDocument = context.document;

      thisDocument.save();

      // Synchronize the document state by executing the queued commands,
      // and return a promise to indicate task completion.
      await context.sync();
    });

    getLastSaveTime().then((lst) => {
      setNewDocument({ ...newDocument, dateValue: lst });
    });

    setShowVersionDialog(false);
  };

  const handleSaveNewVersion = () => saveNewVersion(newDocument);
  const openSaveDialog = () => setShowSaveDialog(true);
  const openSaveSettings = () => setShowSaveSettings(true);
  const handleSelectNewStatus = (_e, v) => {
    setNewDocument({ ...newDocument, status: v.optionValue });
  };
  const handleAttributionSelect = (_e, v) => {
    setSelectedAttribution(getDealUserDisplay(dealUsers.find((member) => member.dealUserID === v.optionValue)));
    setMatchingAttributions(dealUsers); //reset
    setNewDocument({ ...newDocument, attribution: v.optionValue });
  };
  const handleDescription = (e) => setNewDocument({ ...newDocument, description: e.target.value });
  const handleTimeChange = (value) => {
    setNewDocument({ ...newDocument, dateValue: new Date(value).getTime() });
  };
  const handleVersionExists = () => {
    setNewDocument({ ...newDocument, date: 'now', dateValue: null });
    setShowVersionDialog(false);
  };
  const dealVersionClick = (key) => {
    const url = getBaseUrl() + sortedVersions.find((v) => v.key === key).deepLink;

    openInNewTab(url);
  };

  const getDealUserDisplay = (dealUser) => {
    if (!dealUser) return;

    let display = dealUser?.fullName ? `${dealUser.fullName} (${dealUser.email})` : dealUser.email;

    if (dealUser.dealUserID === user.id) {
      display += ' (you)';
    }

    return display;
  };

  const nearestMinute = (time) => {
    const coeff = 1000 * 60 * 1;
    const rounded = Math.round(time / coeff) * coeff;

    return rounded;
  };

  const latestVersion = getLatestVersion(versions);
  const isInvalidTime = newDocument.date !== 'now' && hasMatchingTimestamp;
  const disableSave = !hasRequiredFields() || isInvalidTime;
  const sortedMatchingAttributions = matchingAttributions.sort((a, b) =>
    (a.fullName || a.email) > (b.fullName || b.email) ? 1 : -1
  );
  const hasNewChanges = hasChanges && !showSaveDialog && versionCount > 0 && hasRequiredFields();
  const saveBtnText = !versionCount
    ? 'Save file to repository'
    : newDocument.date === 'now'
    ? `Save latest version`
    : `Save version`;

  // const member = dealUsers.find((member) => member.dealUserID === newDocument.attribution);

  return (
    <>
      <DialogAlert
        title="Version exists"
        buttonText="Save Now"
        cancelText="Use Current Date/Time"
        onChange={handleVersionExists}
        onClick={saveAndUpdateDoc}
        isVisible={showVersionDialog}
      >
        A version with this timestamp already exists.
      </DialogAlert>

      <div className="container" style={{ marginBottom: '16px' }}>
        {hasNewChanges && (
          <section style={styles.root}>
            <Alert intent="info" action="Sync now" icon={<SyncIcon />} onClick={openSaveDialog}>
              New changes detected
            </Alert>
          </section>
        )}

        {showSaveDialog && (
          <React.Fragment>
            <div style={styles.split}>
              <div style={styles.grow}>{!versionCount ? 'Save file as new document' : 'Save file as new version'}</div>
              {!showSaveSettings && (
                <div>
                  <Link appearance="subtle" onClick={openSaveSettings}>
                    Start
                  </Link>
                </div>
              )}
            </div>

            {showSaveSettings && (
              <>
                <section>
                  <label htmlFor="status">Status</label>

                  <Dropdown
                    id="status"
                    value={selectedTemplateData?.workflow.steps.find((step) => step.key === newDocument.status).name}
                    onOptionSelect={handleSelectNewStatus}
                    style={styles.fluidInput}
                    placeholder="Select status"
                    size="small"
                  >
                    {selectedTemplateData &&
                      selectedTemplateData.workflow.steps.map((step) => (
                        <Option
                          key={step.key}
                          value={step.key}
                          text={step.name}
                          checkIcon={<></>}
                          style={styles.option}
                        >
                          <React.Fragment>
                            <SquareIcon color={step.color} style={{ minWidth: '16px' }} />
                            <div style={styles.stepName}>{step.name}</div>
                          </React.Fragment>
                        </Option>
                      ))}
                  </Dropdown>
                </section>
                <section>
                  <div>
                    <label htmlFor="attribution">Attribution</label>
                  </div>
                  <Combobox
                    id="attribution"
                    freeform
                    onInput={hanldeAttributionSearch}
                    onOptionSelect={handleAttributionSelect}
                    style={styles.fluidInput}
                    value={selectedAttribution}
                    size="small"
                    styles={styles.attribution}
                    spellCheck={false}
                    disabled={dealUsers.length <= 1}
                  >
                    {!!sortedMatchingAttributions.length > 0 &&
                      sortedMatchingAttributions.map((member) => (
                        <Option
                          key={member.dealUserID}
                          value={member.dealUserID}
                          text={member.fullName || member.email}
                          style={{
                            display: 'inline-block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          checkIcon={<></>}
                        >
                          {getDealUserDisplay(member)}
                        </Option>
                      ))}
                  </Combobox>
                </section>
                <section>
                  <div>
                    <label htmlFor="description">Description</label>
                  </div>
                  <Textarea
                    id="description"
                    onBlur={handleDescription}
                    defaultValue={newDocument.description || ''}
                    placeholder="(Optional)"
                    style={styles.fluidInput}
                    size="small"
                  />
                </section>
                <div>
                  <label htmlFor="date">Last modified</label>

                  <RadioGroup
                    id="date"
                    value={newDocument.date}
                    onChange={async (_e, v) => {
                      const updates = { date: v.value };

                      switch (v.value) {
                        case 'now':
                          updates.dateValue = null;
                          break;
                        case 'last-modified':
                          updates.dateValue = lastSaveTime.getTime();
                          break;
                        case 'custom-date':
                          updates.dateValue = new Date().getTime();
                          break;
                      }

                      console.log({ updates });

                      setNewDocument({ ...newDocument, ...updates });
                    }}
                  >
                    <Radio label="Current date and time" value="now" selected />
                    <Radio
                      label={`Inherit from file${
                        newDocument.date === 'last-modified' ? `: ${getPrettyDateTime(lastSaveTime)}` : ''
                      }`}
                      value="last-modified"
                    />
                    <Radio label="Custom..." value="custom-date" />
                  </RadioGroup>
                </div>
                {newDocument.date === 'custom-date' && (
                  <React.Fragment>
                    <DateTimePicker
                      currentDateTime={newDocument.dateValue}
                      minDateString={minDateString}
                      onChange={handleTimeChange}
                      onDateValidation={setDateValidationMsg}
                    />
                  </React.Fragment>
                )}

                {!!dateValidationMsg && (
                  <Alert intent="info" style={styles.dateValidationMsg}>
                    {dateValidationMsg}
                    {/* {newDocument.date === 'last-modified' && 'save doc and try again'} */}
                  </Alert>
                )}

                {errorMsg && <Alert>{errorMsg}</Alert>}

                {hasRequiredFields() && newDocument.date !== 'now' && hasMatchingTimestamp && (
                  <Alert intent="info">
                    A version with this timestamp already exists. Please choose another time or select "Current date and
                    time"
                  </Alert>
                )}

                <Button
                  size="medium"
                  disabled={disableSave}
                  onClick={handleSaveNewVersion}
                  style={{ ...styles.submit, ...styles.actionButton }}
                >
                  {saveBtnText}
                </Button>

                <Button size="medium" onClick={onCancel} style={styles.actionButton}>
                  Back
                </Button>
              </>
            )}
          </React.Fragment>
        )}

        {!showSaveDialog && <section style={styles.section}>Versions</section>}
      </div>

      {!!versionCount && <Divider />}

      <div>
        {sortedVersions.length > 0 && (
          <>
            <div className="container">
              <span style={styles.versionLabel}>Versions</span>
            </div>

            {sortedVersions.map((version, i) => {
              const deal = {
                ...version.deal,
                ...{
                  //Modify for existing deal version display
                  parties: [],
                },
              };

              const workflowColor = selectedTemplateData?.workflow.steps.find(
                (step) => step.key === version.dealStatus
              ).color;

              const filename =
                version.docxKey || version.pdfKey
                  ? version.filename
                  : addFileExtension(version.deal.name, version?.origin);

              return (
                <DealVersion
                  key={version.key}
                  isLatest={latestVersion.key === version.key}
                  index={!!versionCount ? versionCount - i : 1}
                  deal={deal}
                  version={version}
                  user={user}
                  workflowColor={workflowColor}
                  onClick={dealVersionClick}
                  showTitle={false}
                  title={version.title}
                  filename={filename}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default VersionsTab;
