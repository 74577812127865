import * as React from 'react';

import { Button, Image, Input, Label, Link, Spinner, makeStyles, useId } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';

import { PROVIDERS } from '@root/Auth';

import viewContext from '../contexts/viewContext';
import { fireAuth as Auth, config as CONFIG } from '../index';
import SingleSignOnButton from './SingleSignOnButton';

const useStyles = makeStyles({
  root: {
    marginTop: '16px',
    marginRight: '16px',
    marginBottom: 0,
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '16px',
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    marginTop: '16px',
  },
  centered: {
    textAlign: 'center',
  },
  link: {
    color: '#8A8886',
  },
  instance: { color: '#8A8886' },
});

const MIN_PASSWORD_LENGTH = 8;

const loginForm = () => {
  const [isValid, setIsValid] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [tryAgain, setTryAgain] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const emailId = useId('input-email');
  const passwordId = useId('input-password');
  const styles = useStyles();
  const view = React.useContext(viewContext);

  const checkIsValid = () => {
    const validPassword = password.length >= MIN_PASSWORD_LENGTH;

    setIsValid(validPassword);
  };

  const emailLogin = () => {
    setIsLoading(true);

    Auth.emailLogin(
      email,
      password,
      () => console.log('email success!'),
      (e) => {
        console.log('email failure');
        setIsLoading(false);
        setErrorMsg(e);
      }
    );
  };

  const reset = () => {
    setIsLoading(false);
    setTryAgain(false);
    view.dialog.close();
  };

  if (isLoading) {
    return (
      <div className="container">
        <h2>Loading...</h2>
        <Spinner />
        {tryAgain && <Button onClick={reset}>Back</Button>}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Image src="../../../assets/logo.png" width="86" />

      <div>
        <h2>Save Word Documents to your Outlaw repository</h2>
        <div className={styles.instance}>
          <b>{CONFIG.DOMAIN}</b>{' '}
          <Link onClick={() => view.addToViewStack('instance')} className={styles.link} inline={true}>
            Change instance
          </Link>
        </div>
      </div>

      {view.loggedOut && (
        <section>
          <Alert intent="info">You have been logged out.</Alert>
        </section>
      )}

      {errorMsg && (
        <section>
          <Alert intent="error">{errorMsg}</Alert>
        </section>
      )}

      <label>Log in</label>

      {_.map(PROVIDERS, (provider) => {
        return (
          <SingleSignOnButton
            key={provider.id}
            domain={provider.id}
            providerName={provider.name}
            dealerLabel="login"
            textTemplate="Continue with [PROVIDER]"
            onClick={() => {
              setErrorMsg(null);
              setIsLoading(true);
            }}
            onError={(e) => {
              setErrorMsg(e);
            }}
          />
        );
      })}

      <div className={(styles.column, styles.centered)}>OR</div>
      <div className={styles.column}>
        <Label htmlFor={emailId}>Email address</Label>
        <Input
          placeholder="you@company.com"
          type="email"
          inputId={emailId}
          appearance="underline"
          onKeyUp={checkIsValid}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          spellCheck={false}
        />
      </div>
      <div className={styles.column}>
        <Label htmlFor={passwordId}>Password</Label>
        <Input
          type="password"
          placeholder={`Min ${MIN_PASSWORD_LENGTH} characters`}
          minLength={MIN_PASSWORD_LENGTH}
          inputId={passwordId}
          appearance="underline"
          onKeyUp={checkIsValid}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          spellCheck={false}
        />
      </div>
      <div className={styles.column}>
        <Button disabled={!isValid} onClick={emailLogin}>
          Log In
        </Button>
      </div>
      <div className={styles.columns}>
        <Link href={`//${CONFIG.DOMAIN}/forgot`} target="_blank" className={styles.link}>
          Forgot password
        </Link>
        <Link href={`//${CONFIG.DOMAIN}/signup`} target="_blank" className={styles.link}>
          Create an account
        </Link>
      </div>
    </div>
  );
};

export default loginForm;
