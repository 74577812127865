const config = {
  FIREBASE: {
    apiKey: 'AIzaSyAx9oJfj62yp00x1FrYXGeu8LVG9_1_urc',
    //authDomain: "outlaw-invincible.firebaseapp.com",
    authDomain: 'invincible.getoutlaw.com',
    projectId: 'outlaw-invincible',
    storageBucket: 'outlaw-invincible.appspot.com',
    messagingSenderId: '912260801412',
    appId: '1:912260801412:web:1a5095621acc46983b33d1',
  },
  INSTANCE: 'invincible',
  DOMAIN: 'invincible.getoutlaw.com',
};

module.exports = config;
