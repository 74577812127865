const config = {
  FIREBASE: {
    apiKey: 'AIzaSyAVI85CA9N8gOTRz62lp97DUugo_-cNRuU',
    //authDomain: "outlaw-filevine.firebaseapp.com",
    authDomain: 'fv.getoutlaw.com',
    projectId: 'outlaw-filevine',
    storageBucket: 'outlaw-filevine.appspot.com',
    messagingSenderId: '687907123795',
    appId: '1:687907123795:web:3344d4daba680416354ca6',
    measurementId: 'G-2D4MF3FX31',
  },
  INSTANCE: 'fv',
  DOMAIN: 'fv.getoutlaw.com',
};

module.exports = config;
