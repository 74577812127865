import * as React from 'react';

import { Spinner } from '@fluentui/react-components';
import PropTypes from 'prop-types';

export default class Progress extends React.Component {
  render() {
    const { logo, message, title, spinner = true } = this.props;

    return (
      <section className="ms-welcome__progress ms-u-fadeIn500">
        <img width="90" src={logo} alt={title} title={title} />
        <h1>{title}</h1>
        {spinner && <Spinner size="small" label={message} />}

        {!spinner && message}
      </section>
    );
  }
}

Progress.propTypes = {
  logo: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};
