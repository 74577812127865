import * as React from 'react';

import { Button, Input, Label, Radio, RadioGroup, useId } from '@fluentui/react-components';
import { Alert, Dropdown, Option } from '@fluentui/react-components/unstable';
import { Dismiss12Regular as CancelIcon, Search20Regular as SearchIcon } from '@fluentui/react-icons';

import documentContext from '../contexts/documentContext';
import FacetSearch from './FacetSearch';

const AdvancedSearch = ({ filterValue = '', filterType, menus, user, onSearch, onBack }) => {
  const FILTER_FACETS = {
    title: {
      name: 'Title',
      facet: 'title',
      description: 'Filter by Document title',
    },
    parties: {
      name: 'Party',
      facet: 'parties',
      description: 'Filter by Party name',
      useSuggestions: true,
    },
    users: {
      name: 'User',
      facet: 'users',
      description: 'Filter by User name/email',
      useSuggestions: true,
    },
  };

  const radioName = useId('radio');
  const labelId = useId('label');

  const styles = {
    root: { display: 'flex', flexDirection: 'column', gap: '8px', padding: '4px 0 0 0' },
    columns: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      verticalAlign: 'middle',
    },
    column: { flex: '0 0 50%', width: 0, overflow: 'hidden', textOverflow: 'ellipsis' },
    pagination: {
      margin: '1rem -0.5rem',
      padding: '0.25rem 0.5rem',
      background: '#f3f3f3',
    },
    searchGroup: {
      paddingBottom: '4px',
    },
    searchTitle: { margin: 0 },
    fullWidth: {
      width: '100%',
    },
    backBtn: {
      padding: 0,
      margin: 0,
      justifyContent: 'space-between',
      color: 'initial',
      paddingRight: 0,
      minWidth: 'unset',
      width: 'auto',
    },
    teamAlert: { marginTop: '1rem' },
  };

  const { selectedTeam, selectedSuggestions, setSelectedSuggestions } = React.useContext(documentContext);

  const [showSuggestions, setShowSuggestions] = React.useState(false);

  React.useEffect(async () => {
    if (FILTER_FACETS[filterType].useSuggestions) {
      setShowSuggestions(true);
    }
  }, [filterType, filterValue]);

  const setFilterType = (type) => {
    onSearch({ filterType: type, filterValue });
  };

  const useFocus = () => {
    const htmlElRef = React.useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };

  const [inputRef, setInputFocus] = useFocus();

  let filterTimer;
  const handleFilterUpdate = (filter) => {
    clearTimeout(filterTimer);

    filterTimer = setTimeout(() => {
      onSearch({ filterType, filterValue: filter });
      setInputFocus();
    }, 1000);
  };

  const handleFacetToggle = (value) => {
    if (!value) return;

    console.log('handleFacetToggle', value);
    const s = [...selectedSuggestions];
    const index = s.indexOf(value);

    if (index === -1) {
      s.push(value);
    } else {
      s.splice(index, 1);
    }

    console.log('setSelected', s);

    setSelectedSuggestions(s);
    onSearch({ filterType, filterValue: s });
  };

  return (
    <div style={styles.root}>
      <div style={{ ...styles.columns, ...styles.searchGroup }}>
        <h3 style={styles.searchTitle}>Document search</h3>
        <Button
          appearance="transparent"
          icon={<CancelIcon />}
          iconPosition="after"
          onClick={onBack}
          style={styles.backBtn}
          size="small"
        ></Button>
      </div>

      <div>
        {!showSuggestions && (
          <Input
            size="small"
            contentBefore={<SearchIcon />}
            placeholder={FILTER_FACETS[filterType].description}
            style={{ ...styles.fullWidth, paddingBottom: '1px', paddingTop: '1px' }}
            onChange={(e) => {
              handleFilterUpdate(e.target.value);
            }}
            spellCheck={false}
            defaultValue={filterValue}
            ref={inputRef}
            autoFocus={true}
          />
        )}
        {showSuggestions && (
          <FacetSearch
            facet={filterType}
            empty="None found"
            selected={selectedSuggestions}
            toggleSelection={handleFacetToggle}
            style={styles.fullWidth}
          />
        )}
        <div role="radiogroup" aria-labelledby={labelId}>
          <RadioGroup layout="horizontal" value={filterType}>
            {Object.keys(FILTER_FACETS).map((key) => (
              <Radio
                key={key}
                name={radioName}
                value={FILTER_FACETS[key].facet}
                label={FILTER_FACETS[key].name}
                size="small"
                onChange={(e) => {
                  setFilterType(e.target.value);
                }}
                className="radio-small"
              />
            ))}
          </RadioGroup>
        </div>
      </div>

      <div>
        {menus.map((Menu) => (
          <Menu style={styles.fullWidth} />
        ))}
      </div>

      {!selectedTeam && (
        <Alert intent="info" style={styles.teamAlert}>
          Please select a team
        </Alert>
      )}
    </div>
  );
};

export default AdvancedSearch;
