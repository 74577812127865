import * as React from 'react';

import { useId } from '@fluentui/react-components';
import { Dropdown, Option } from '@fluentui/react-components/unstable';

const TeamMenu = ({ teams = [], onOptionSelect, disabled, selectedTeam, size, ...props }) => {
  const dropdownId = useId('dropdown-default');

  return (
    <>
      <Dropdown
        aria-labelledby={dropdownId}
        placeholder={teams.length > 0 ? 'Select a team' : 'No teams available for this user'}
        value={selectedTeam && selectedTeam?.info.name}
        onOptionSelect={(_e, v) => {
          onOptionSelect(teams.find((team) => team.teamID === v.optionValue));
        }}
        size={size}
        disabled={disabled}
        selectedOption={selectedTeam}
        {...props}
      >
        {teams
          .sort((a, b) => a.info.name.toLowerCase() > b.info.name.toLowerCase())
          .map((team) => {
            return (
              <Option key={team.teamID} text={team.info.name} value={team.teamID} checkIcon={<></>}>
                {disabled ? 'Loading team ...' : team.info.name}
              </Option>
            );
          })}
      </Dropdown>
    </>
  );
};

export default TeamMenu;
