export const getFormattedTime = (time, useRounding = false) => {
  const hours = time.getHours();
  let minutes = time.getMinutes();
  useRounding && (minutes = Math.round((minutes - 7.5) / 15) * 15);
  if (minutes < 10) minutes = `0${minutes}`;
  const ampm = hours >= 12 ? 'PM' : 'AM';

  return `${hours > 12 ? hours % 12 : hours}:${minutes} ${ampm}`;
};

export const getTimeParts = (ts) => {
  const t = ts.split(/:| /);
  const h = +t[0] || 12;
  const m = +t[1] || 0;
  const ampm = t[2].toLowerCase();

  console.log({ t, h, m, ampm });

  return { h, m, ampm };
};

export const getTimestring = ({ h, m, ampm }) => `${h}:${m < 10 ? '0' + m : m} ${ampm}`;

export const getFullDate = ({ date, time }) => {
  return `${date.toLocaleDateString('en-US')} ${time}`;
};

export const parseTime = (t) => {
  let h, m, ampm;

  try {
    ({ h, m, ampm } = getTimeParts(t));
  } catch (e) {
    console.log('parseTime error', e);
    return false;
  }

  if (typeof h !== 'number' || h < 1) h = 1;
  if (h > 12) {
    h %= 12;
  }

  if (m < 0 || m > 59) {
    return false;
  }

  if (!['am', 'pm'].includes(ampm)) {
    return false;
  }

  const timestring = getTimestring({ h, m, ampm });

  // setTime(timestring);

  return timestring;
};

export const getPrettyDate = (date) => date.toLocaleDateString('en-CA');
export const getPrettyDateTime = (date) => `${getPrettyDate(date)} @ ${getFormattedTime(date)}`;

export const checkDate = (minDate, newDate, onDateValidation) => {
  if (!minDate) {
    return true;
  }
  const isValid = newDate > minDate;

  onDateValidation(
    !isValid && minDate ? `This version’s timestamp can not be earlier than (${getPrettyDateTime(minDate)})` : false
  );

  return isValid;
};
