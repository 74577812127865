import * as React from 'react';

import { DEAL_TYPE } from '@getoutlaw/outlaw-core/models/Deal';

const docx = '/assets/20x20-file-docx.svg';
const pdf = '/assets/20x20-file-pdf.svg';
const draft = '/assets/20x20-draft.svg';
const native = '/assets/20x20-native.svg';
const vine = '/assets/20x20-file-vine.svg';

const getIcon = (dealType) => {
  switch (dealType) {
    case DEAL_TYPE.INGESTED:
    case DEAL_TYPE.EXTERNAL_WORD:
      return docx;
    case DEAL_TYPE.EXTERNAL:
      return pdf;
    case DEAL_TYPE.BESPOKE:
      return draft;
    case DEAL_TYPE.NATIVE:
      return vine;
    default:
      return native;
  }
};

const DocumentIcon = ({ docType, color, ...props }) => {
  return <img src={getIcon(docType)} alt={docType} {...props} />;
};

export default DocumentIcon;
