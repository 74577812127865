import * as React from 'react';

import { Spinner } from '@fluentui/react-components';
import User from '@getoutlaw/outlaw-core/models/User';
import { Stopwatch } from '@getoutlaw/outlaw-core/utils';
import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import Dealer from '@root/Dealer';

import viewContext from '../contexts/viewContext';
// import Fire from '@root/Fire';
import { fireAuth as Auth, fireApp as Fire, initAuth } from '../index';
import routes, { loggedInRoute } from '../routing/routes';
import { getCookie } from '../utils/browser';
import Header from './Header';
import Progress from './Progress';
import TopBar from './TopBar';

/* global Word */

const Switch = ({ current, children }) => {
  return children;
};

const getRouteByPath = (path) => routes.find((route) => route.path === path);

const RouteWithSubRoutes = (route, ...props) => {
  return <route.component {...props} routes={route.routes} />;
};

const OFFICE_MIN_VERSION = '16.0.13530.20424';

@autoBindMethods
class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentView: null,
      viewStack: [],
      dialog: null,
      updatingAuth: true,
      user: undefined, // Outlaw user profile info
      account: undefined, // Underlying Firebase account
      authChange: this.authChange,
      authChangeInProgress: this.authChangeInProgress,
      loggedOut: false,
      instance: getCookie('instance') || 'app',
    };

    this.sw = new Stopwatch('APP');
  }

  static currentView = viewContext;

  hasRequiredAuth = (view) => {
    const route = getRouteByPath(view);

    return route.protected ? this.state.isAuthenticated : true;
  };

  setCurrentView = (view) => this.setState({ currentView: view });

  addToViewStack = (view) => {
    if (this.currentView === view || !this.hasRequiredAuth(view)) return;

    this.setState((prevState) => ({ ...prevState, viewStack: prevState.viewStack.concat(view) }));
  };

  removeFromViewStack = () =>
    this.setState((prevState) => ({ viewStack: prevState.viewStack.slice(0, prevState.viewStack.length - 1) }));

  setDialog = (dialog) => {
    console.log('[App] setDialog', dialog);
    this.setState({ dialog });
  };

  authInit = () => {
    initAuth(this.state.authChange, this.authChangeInProgress);
  };

  setInstance = (instance) => {
    console.log('[App] setInstance', instance);
    document.cookie = `instance=${instance}`;

    this.props.onInit(instance);
    this.authInit();

    this.setState({ instance });
  };

  componentDidMount() {
    this.history = window.history;

    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');

    this.addToViewStack(view || '/');

    if (this.state.instance !== 'app') this.setInstance(this.state.instance);

    this.authInit();

    console.log('[App] Auth', Auth);
    // CASE 1 -- Existing Account
    // user is not currently logged in, and has attempted to login with a different provider
    // but provider has email address that matches an existing account
    // we need to warn user and instruct them to login via existing provider, then link accounts
    const diffCred = (currentProviders, pendingCred) => {
      console.log('diffCred');
      const other = _.find(PROVIDERS, { id: currentProviders[0] }); // only require to return one of the current providers.
      const pending = _.find(PROVIDERS, { id: pendingCred.providerId });
      let loginError = `You already created an Outlaw account. Sign in with it again and then link your ${pending.name} login in your Profile.`;
      if (other) {
        loginError = `You already created an Outlaw account through ${other.name}. Sign in with ${other.name} again and then link your ${pending.name} login in your Profile.`;
      }
      this.setState({ loginError });
    };

    // CASE 2 -- Anonymous Migration
    // User is already authed anonymously (e.g., from guest login) and then logs into an EXISTING account
    // NOTE: this will only happen if the account already existed.
    // If when logging in the user establishes a NEW account (new email),
    // none of this will fire as the anonymous account will simply become the auth'd one (same uid)
    const mergeAnonymous = async (anonUser, pendingCred) => {
      console.log('mergeAnonymous');
      await this.setState({ updatingAuth: true });

      // We're initially still authenticated as an anonymous user
      // so we need to grab current auth token for use in account merge call
      // before we proceed with signing in with the supplied credentials
      const fromToken = await Fire.token();

      // Remove potential Fire listener on user account so that we don't get a security error
      // when we login with the new credential (next line)
      this.unwatchProfile(anonUser);

      try {
        console.log('try to log in');
        // Move forward with signing in with the (existing) account the user just auth'd
        await Auth.login(null, pendingCred);

        // Now we've got both the old guest token and the fully logged in account
        // Head over to /mergeAccounts for safe merging (i.e., while not looking at any data),
        // which will then come back to the current route when done
        const { location, history } = this.props;
        const params = qs.parse(location.search);
        let url = `/mergeAccounts?fromToken=${fromToken}`;
        console.log('url', url);
        if (params.redirect) {
          url += `&redirect=${encodeURIComponent(params.redirect)}`;
        }
        console.log('url2', url);

        history.push(url);
        await this.setState({ updatingAuth: false });
      } catch (err) {
        //handle error so that we don't end up with infinite spinner
        console.log('caught err', err);
        await this.setState({ updatingAuth: false });
      }
    };

    // CASE 3 -- Linking Providers
    // here and we have both a pending credential AND a currently logged in user
    // but the providers are different, so the user is linking accounts
    const linkAccounts = (currentUser, pendingCred) => {
      console.log('linkAccounts');
      if (currentUser) {
        this.setState({ updatingAuth: true });
        currentUser.linkWithCredential(pendingCred).then(
          () => {
            console.log(`[AUTH] Account successfully linked to [${pendingCred.providerId}]`);
            this.setState({ updatingAuth: false });
          },
          (err) => {
            console.log(`[AUTH] Error linking account to [${pendingCred.providerId}]`, err);
            this.setState({ updatingAuth: false });
          }
        );
      }
    };

    console.log('should handle login redirect');

    Auth.handleLoginRedirect({ diffCred, mergeAnonymous, linkAccounts });
  }

  // Establish an always-on listener for user profile data
  // which will fire anytime any piece changes (e.g., profile info, team membership, etc)
  // This is intentionally separate from other "first-time-only" aspects of initial auth (see authChange)
  async watchProfile(account) {
    let completeCheck = false;

    return new Promise((resolve) => {
      Fire.getUser(account.uid, true, async (json) => {
        this.sw.step(`Got profile info for user [${account.uid}]`);
        const user = new User(json);

        if (!account.isAnonymous && !completeCheck) {
          await Fire.ensureProfileCompleteness(user, account);
          this.sw.step('Finished profile completeness check');
          completeCheck = true;
        }

        // TODO: only one of these should exist eventually;
        // Either update app state and pass down, or use mobx to have models watching each other
        Model.user = user;
        this.setState({ user });
        resolve(user);
      });
    });
  }

  unwatchProfile(account) {
    console.log('unwatchprofile', Fire);
    Fire.db.ref(`users/${account.uid}`).off();
  }

  async authChange(account, anonEmail) {
    const { user: currentUser, wlContext } = this.state;
    const { location, history } = this.props;

    this.sw.step(`Auth change with user [${_.get(account, 'uid', 'null')}]`);

    // This will cause pre-loader to show
    // Don't allow any routes to render until we're done app bootstrap
    await this.setState({ updatingAuth: true });

    // If we had a previous user logged in, immediately stop watching that user's profile
    if (currentUser) this.unwatchProfile({ uid: currentUser.id });

    // Load user profile here, which also establishes the live listener
    // But using await here instead of callback will cause this to only fire once (which is what we want)
    const user = account ? await this.watchProfile(account) : null;

    if (user) {
      //We used to get the user's IP here
      user.userAgent = _.get(window, 'navigator.userAgent', null);
    }

    if (account) Dealer.login(account);

    //if we have a real (normal) non-anonymous user, run additional checks:
    //push latest CRM data to Intercom, confirm subscription, etc
    if (user && account && !account.isAnonymous) {
      //We used to do some CRM stuff here
      // const subscription = await this.checkSubscription();
      // this.sw.step(`Subscription checked: [${subscription.status}]`);
      // await this.getTeams(user);
      // this.sw.step(`Loaded user's teams (${_.keys(user.teams).length})`);
    }

    // Finally, we're done app bootstrap; update state to re-render app with user/account
    this.sw.step(`Auth check complete; proceeding with app rendering`);
    await this.setState({ user, account, anonEmail, updatingAuth: false });

    // If user WAS logged in but has logged out, redirect to login with former path captured

    if (currentUser && !account && false) {
      //We don't do this yet
      console.log(`[AUTH] Formerly authed user logged out from [${location.pathname}]; redirecting to login`);
      // Clear all LocalStorage properties used in app
      LS.clear();
      // Logout of Intercom
      CRM.logout();
      // Redirect to login route
      if (isVine) {
        history.replace(`/wllogin/?redirect=${encodeURIComponent(location.pathname)}`);
      } else {
        history.replace(`/login/?redirect=${encodeURIComponent(location.pathname)}`);
      }
    }

    this.state.dialog.close();
    this.setState({ dialog: null });

    return Promise.resolve();
  }

  async authChangeInProgress() {
    console.log('[App] auth change in progress');
    this.setState({ updatingAuth: true });
  }

  setDocProperty(propName, propValue, callback) {
    Word.run((context) => {
      context.document.properties.customProperties.add(propName, propValue);
      return context
        .sync()
        .then(() => {
          callback(null);
        })
        .catch((e) => {
          callback(new Error(e));
        });
    });
  }

  render() {
    const { title, isOfficeInitialized, documentProperties, isSupported } = this.props;

    const { existingDealID } = documentProperties;

    if (!isSupported) {
      return (
        <Progress
          title={title}
          logo={require('./../../../assets/logo.png')}
          message={`Outlaw Add-in for Word not compatible with this version of Office.`}
          spinner={false}
        />
      );
    }

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require('./../../../assets/logo.png')}
          message="Please sideload your addin to see app body."
        />
      );
    }

    return (
      <viewContext.Provider
        value={{
          currentView: this.state.currentView,
          setCurrentView: this.setCurrentView,
          addToViewStack: this.addToViewStack,
          removeFromViewStack: this.removeFromViewStack,
          setDialog: this.setDialog,
          dialog: this.state.dialog,
          user: this.state.user,
          loggedOut: this.state.loggedOut,
          setLoggedOut: (loggedOut) => this.setState({ loggedOut }),
          setDocumentProperty: (propName, propValue, callback) => this.setDocProperty(propName, propValue, callback),
          existingDealID,
          instance: this.state.instance,
          setInstance: this.setInstance,
        }}
      >
        <div className="ms-welcome">
          {/*<Header onBack={this.removeFromViewStack} showBack={this.state.viewStack.length > 1} />*/}
          <TopBar />
          {this.state.updatingAuth && <Spinner style={{ marginTop: '16px' }} />}
          {!this.state.updatingAuth && !this.state.user && (
            <Switch current={this.state.currentView} routes={routes}>
              {routes.map(
                (route, i) =>
                  route.path === (this.state.viewStack.at(-1) || '/') && <RouteWithSubRoutes key={i} {...route} />
              )}
            </Switch>
          )}
          {!this.state.updatingAuth && this.state.user && <RouteWithSubRoutes {...loggedInRoute} />}
        </div>
      </viewContext.Provider>
    );
  }
}

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
  onInit: PropTypes.func,
};

export default App;
