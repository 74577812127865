import * as React from 'react';

import { Divider, Link } from '@fluentui/react-components';
import { LockClosed12Filled as LockIcon } from '@fluentui/react-icons';
import { tokens } from '@fluentui/react-theme';

import { getPrettyDateTime } from '../utils/time';
import DocumentIcon from './DocumentIcon';
import Party from './Party';
import SingleLine from './SingleLine';
import StatusLabel from './StatusLabel';

const Version = ({
  index,
  dealID,
  isLatest,
  status,
  title = 'Untitled',
  filename,
  description,
  createdDate,
  workflowColor,
  parties,
  canEdit,
  onClick,
  link,
  children,
  origin,
  showTitle = true,
  ...props
}) => {
  const [titleHovered, setTitleHovered] = React.useState(false);

  const styles = {
    root: { gap: '8px' },
    topSection: {
      // marginBottom: '0.5rem',
    },
    bottomSection: {
      borderLeft: `2px solid ${isLatest ? tokens.colorBrandForegroundLink : '#ccc'}`,
      paddingLeft: '16px',
      marginBottom: '4px',
      fontSize: '12px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    title: {
      padding: 0,
      margin: 0,
      fontWeight: 600,
      lineHeight: 1,
      textDecoration: onClick && titleHovered ? 'underline' : 'none',
      cursor: onClick ? 'pointer' : 'default',
      display: 'inline-block',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '14px',
    },
    info: {
      flexGrow: 2,
      lineHeight: 1.5,
    },
    parties: {
      // marginBottom: '8px',
      lineHeight: '1.25',
    },
    version: { fontWeight: 600, paddingRight: '8px' },
    doc: {
      textDecoration: 'underline',
    },
    description: {
      fontStyle: 'italic',
    },
    link: {
      display: 'inline-flex',
      maxWidth: '100%',
      alignItem: 'middle',
      color: 'initial',
      fontSize: '12px',
    },
    icon: { width: 'auto', display: 'inline', marginRight: '8px', marginBottom: '-3px', marginLeft: '-3px' },
    timestamp: {
      color: '#606060',
    },
    columns: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  };

  // const dateString = createdDate.toLocaleDateString('en-US');
  // const timeString = createdDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  const dateTimeString = getPrettyDateTime(createdDate);

  const handleClick = (e) => {
    console.log('click!', onClick, link);
    if (!link && onClick) onClick(e);
  };

  const WrapWithLink = ({ condition, link, onClick, children }) =>
    condition ? (
      <Link onClick={onClick} href={!onClick && link} style={styles.link} target="_blank">
        {children}
      </Link>
    ) : (
      children
    );

  return (
    <>
      <div style={styles.root} className="container">
        {title && showTitle && (
          <WrapWithLink condition={!!link} link={link}>
            <h3
              style={styles.title}
              onClick={() => handleClick(dealID)}
              onMouseOver={() => setTitleHovered(true)}
              onMouseOut={() => setTitleHovered(false)}
            >
              <SingleLine content={title}>
                {!canEdit && <LockIcon style={{ marginRight: '4px' }} />}
                {title}
              </SingleLine>
            </h3>
          </WrapWithLink>
        )}

        {!!parties.length && (
          <div style={styles.parties}>
            {parties.map((party, i) => (
              <Party key={party.partyId + i.toString()} party={party} />
            ))}
          </div>
        )}

        <div style={styles.bottomSection}>
          <div style={styles.info}>
            <div style={styles.columns}>
              <div>
                <span style={styles.version}>V{index}</span>
                <span style={styles.timestamp}>{dateTimeString}</span>
              </div>
              <StatusLabel status={status} color={workflowColor} />
            </div>
            <div style={styles.doc}>
              <WrapWithLink condition={!!link || !!onClick} link={link} onClick={onClick}>
                <SingleLine content={filename}>
                  <>
                    <DocumentIcon
                      docType={origin}
                      color={tokens.colorBrandForegroundLink}
                      height={16}
                      style={styles.icon}
                    />
                    {filename}
                  </>
                </SingleLine>
              </WrapWithLink>
            </div>
            {description && <div style={styles.description}>{description}</div>}
          </div>
        </div>
        {children}
      </div>
      <Divider style={{ margin: '16px 0' }} />
    </>
  );
};

export default React.memo(Version);
